<template>
  <section class="page-content-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="page-title-box">
            <div class="btn-group float-right">
              <ol class="breadcrumb hide-phone p-0 m-0">
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)">JB admin</a>
                </li>
                <li class="breadcrumb-item active">Banners listing</li>
              </ol>
            </div>
            <h4 class="page-title">Dashboard</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <div class="card">
            <div class="card-body border-bottom">
              <h4 class="mb-0">
                {{ boxTitle }}
                <router-link
                  :to="{ name: 'cmsbannersadd' }"
                  class="btn btn-sm btn-outline-primary float-right"
                >
                  Add new</router-link
                >
              </h4>
            </div>
            <div class="card-body">
              <div class="table-responsive table-responsive-sm">
                <table
                  class="table table-sm align-items-center table-flush table-striped"
                >
                  <thead class="thead-light">
                    <tr>
                      <th></th>
                      <th>Title</th>
                      <th>Subtitle</th>
                      <th>Validity</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(banner, index) in banners" :key="index">
                      <td>
                        <img
                          :src="dukaURL + 'uploads/home-slide/' + banner.image"
                          :alt="banner.banner_title"
                          class="img img-fluid rounded"
                          style="width:100px"
                        />
                      </td>
                      <td class="table-user">
                        <b>{{ banner.banner_title }}</b>
                      </td>
                      <td class="expire-date">{{ banner.banner_subtitle }}</td>
                      <td class="expire-date">
                        {{ dukaHotelDate(banner.start_date) }} -
                        {{ dukaHotelDate(banner.expired_date) }}
                      </td>
                      <td class="action">
                        <button
                          class="btn btn-icon btn-danger btn-sm"
                          type="button"
                          @click="deleteBanner(banner.id)"
                        >
                          <span class="btn-inner--icon">
                            <i class="ti-trash"></i>
                          </span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CmsBannersList",
  data: () => ({
    banners: [],
    boxTitle: "List of all Banners",
    getURL: "duka-root/get-banners"
  }),
  created() {
    if (
      this.$route.query["banners-category"] &&
      this.$route.query["banners-category"] === "special-offer"
    ) {
      this.boxTitle = "Special Offer banners";
      this.getURL = "duka-root/get-special-offer-banners";
    }
    this.loadBanners();
  },
  methods: {
    loadBanners() {
      this.$store.dispatch("dukaGetRequest", this.getURL).then(response => {
        this.banners = response.data.dukaResponse;
      });
    },
    deleteBanner(banner_id) {
      if (confirm("Are you sure you want to delete this Banner?")) {
        this.$store
          .dispatch("dukaGetRequest", "duka-root/delete-banner/" + banner_id)
          .then(() => {
            this.loadBanners();
          });
      }
    }
  }
};
</script>
