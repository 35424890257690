<template>
  <section>
    <duka-alert
      :modalTitle="alertDefaults.title"
      :modalContent="alertDefaults.content"
      :actionButton="alertDefaults.actionButton"
      @accept-alert="toggleActions(alertDefaults.actionType)"
      @close="closeAlert"
      :actionButtonClasses="alertDefaults.classes"
      v-if="alertDefaults.modalOpen"
    />

    <duka-modal
      v-if="modalOpen && modalMode.discounting"
      @close="toggleModal"
      style="display:block"
      :modalSize="'default'"
    >
      <span slot="head" style="padding-top:10px"
        >Set discount on this product</span
      >
      <div slot="body">
        <div class="row">
          <label class="col-sm-4 mt-2">Discount name</label>
          <div class="col-sm-8">
            <div class="form-group">
              <input
                type="text"
                v-model="newDiscount.discount_name"
                class="form-control"
                placeholder="Name of discount"
              />
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for class="col-sm-4 mt-2">Discount Price(%)</label>
          <div class="col-sm-8">
            <div class="row no-gutters">
              <div class="col-3">
                <div class="input-group input-group-merge">
                  <input
                    class="form-control"
                    type="text"
                    v-model="newDiscount.discount_perc"
                    @keyup="calculateDiscount()"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <b>%</b>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-9">
                <div class="input-group input-group-merge">
                  <div class="input-group-prepend">
                    <span class="input-group-text">RWF</span>
                  </div>
                  <input
                    class="form-control"
                    type="text"
                    v-model="newDiscount.discount"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4">Start Date</label>
          <div class="col-sm-8">
            <datepicker
              v-model="start_date"
              calendar-button-icon="dripicons-calendar"
              name="start_date"
              inputClass="form-control"
              :clear-button="true"
              :bootstrap-styling="true"
              format="dd-MMM-yyyy"
              id="start-date"
            >
              <div slot="beforeCalendarHeader" class="calender-header">
                Starting date
              </div>
            </datepicker>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4">Expired date</label>
          <div class="col-sm-8">
            <datepicker
              v-model="expired_date"
              calendar-button-icon="dripicons-calendar"
              name="expired_date"
              inputClass="form-control"
              :clear-button="true"
              :bootstrap-styling="true"
              format="dd-MMM-yyyy"
              id="expired-date"
            >
              <div slot="beforeCalendarHeader" class="calender-header">
                Expired date
              </div>
            </datepicker>
          </div>
        </div>
      </div>

      <div slot="foot">
        <duka-button
          :loadText="'please wait...'"
          :caption="'Set discount'"
          @btn-role="setDiscount"
          :classes="'btn btn-primary'"
          :disabler="!newDiscount.discount_perc"
          :activator="'settingsinglediscount'"
        />
      </div>
    </duka-modal>

    <!-- QUntity editing-->
    <duka-modal
      v-if="modalOpen && modalMode.qtyEditing"
      @close="toggleModal"
      style="display:block"
      :modalSize="'dafault'"
    >
      <span slot="head" style="padding-top:10px">Edit Quantity</span>
      <div slot="body">
        <div class="row">
          <label for class="col-sm-4 mt-2">Current quantity:</label>
          <div class="col-sm-8">
            <input
              type="text"
              v-model="choosenRow.quantity"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div slot="foot">
        <button
          type="button"
          class="btn btn-primary"
          :disabled="!choosenRow.quantity"
          @click="updateQuantity"
        >
          Update Quantity
        </button>
      </div>
    </duka-modal>
    <!-- Edit product-->

    <duka-modal-form
      v-if="modalOpen && modalMode.priceEditing"
      @close="toggleModal"
      style="display:block"
      :modalSize="'default'"
      :headerColor="'#041e42'"
    >
      <span slot="head" style="padding-top:10px">Edit pricing options</span>
      <div slot="body">
        <div class="form-group row">
          <div class="col-sm-6">
            <label class="w-100">Orginal Price</label>
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                <span class="input-group-text">RWF</span>
              </div>
              <input
                class="form-control"
                type="text"
                v-model="choosenRow.orginal_price"
                @keyup="togglePrices(true)"
              />
            </div>
          </div>

          <div class="col-sm-6">
            <label>Retail Price(%)</label>
            <div class="row no-gutters">
              <div class="col-3">
                <div class="input-group input-group-merge">
                  <input
                    class="form-control"
                    type="text"
                    v-model="choosenRow.retail_perc"
                    @keyup="calculateRetailPrice(true)"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <b>%</b>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-9">
                <div class="input-group input-group-merge">
                  <div class="input-group-prepend">
                    <span class="input-group-text">RWF</span>
                  </div>
                  <input
                    class="form-control"
                    type="text"
                    v-model="choosenRow.retail_price"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-6 card py-1">
            <div class="card-body px-0">
              <h4>Wholesale Pricing</h4>
              <div class="w-100 mt-4">
                <span
                  style="display: inline; position: relative; top: -3px; left: -2px;"
                  >Set Whole sale Price?</span
                >
                <label class="custom-toggle float-right">
                  <input
                    type="checkbox"
                    v-model="choosenRow.requiredWholePrice"
                  />
                  <span
                    class="custom-toggle-slider rounded-circle"
                    data-label-off="N/A"
                    data-label-on="Yes"
                  ></span>
                </label>
              </div>
              <template v-if="choosenRow.requiredWholePrice">
                <div class="form-group row">
                  <div class="col-sm-12">
                    <label for class="w-100">Price(%)</label>
                    <div class="row no-gutters">
                      <div class="col-3">
                        <div class="input-group input-group-merge">
                          <input
                            class="form-control"
                            type="text"
                            v-model="choosenRow.wholesale_perc"
                            @keyup="calculateWholePrice(true)"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <b>%</b>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-9">
                        <div class="input-group input-group-merge">
                          <div class="input-group-prepend">
                            <span class="input-group-text">RWF</span>
                          </div>
                          <input
                            class="form-control"
                            type="text"
                            v-model="choosenRow.wholesale_price"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <label for class="w-100">Minimum Qty</label>
                    <input
                      type="text"
                      placeholder="Enter min. quantity"
                      v-model="choosenRow.wholesale_qty"
                      class="form-control"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="w-100 mt-4">
              <span
                style="display: inline; position: relative; top: -3px; left: -2px;"
                >Set Returning customers Price?</span
              >
              <label class="custom-toggle float-right">
                <input
                  type="checkbox"
                  v-model="choosenRow.requiredReturningPrice"
                />
                <span
                  class="custom-toggle-slider rounded-circle"
                  data-label-off="N/A"
                  data-label-on="Yes"
                ></span>
              </label>
            </div>
            <template v-if="choosenRow.requiredReturningPrice">
              <label class="w-100">Percentage(%)</label>
              <div class="row no-gutters">
                <div class="col-3">
                  <div class="input-group input-group-merge">
                    <input
                      class="form-control"
                      type="text"
                      v-model="choosenRow.return_perc"
                      @keyup="calculateReturnPrice(true)"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <b>%</b>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-9">
                  <div class="input-group input-group-merge">
                    <div class="input-group-prepend">
                      <span class="input-group-text">RWF</span>
                    </div>
                    <input
                      class="form-control"
                      type="text"
                      v-model="choosenRow.return_customer"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div slot="foot">
        <button
          type="button"
          class="btn btn-primary ml-auto"
          @click="updatePrices"
        >
          Save changes
        </button>
      </div>
    </duka-modal-form>
    <!-- end of product edit -->

    <div class="container-fluid mt-2">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <div class="card">
            <div class="card-body border-bottom">
              <div class="row">
                <div class="col-6">
                  <h5 class="mb-0">List of your products</h5>
                </div>
                <div class="col-6 text-right">
                  <router-link
                    :to="{ name: 'categoriespickup' }"
                    class="btn btn-outline-primary btn-icon"
                  >
                    <span class="btn-inner--text">Add new product</span>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div
                class="d-flex flex-wrap justify-content-center justify-content-sm-between pb-3"
              >
                <div class="d-flex flex-wrap">
                  <div class="form-inline flex-nowrap pb-3">
                    <label class="mr-2 d-none d-sm-block" for="number"
                      >Show:</label
                    >
                    <select
                      class="form-control custom-select mr-sm-2"
                      id="number"
                      @change="changePaginationRows($event)"
                    >
                      <option>12</option>
                      <option>24</option>
                      <option>48</option>
                      <option>96</option> </select
                    ><small
                      class="form-text text-muted text-nowrap d-none d-sm-block"
                      >From {{ recordsLength }} Products
                    </small>
                  </div>
                </div>
                <div class="form-inline d-md-flex flex-nowrap pb-3">
                  <input
                    type="search"
                    v-model="filterKey"
                    placeholder="Search for a product"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="table-responsive" style="padding-bottom:60px;">
                <table class="table align-items-center table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th></th>
                      <th>Product</th>
                      <th>Category</th>
                      <th class="text-center">Action</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(product, index) in filteredRecords"
                      :key="index"
                    >
                      <td>
                        <img
                          :src="dukaURL + 'uploads/' + product.image"
                          :alt="product.product_title"
                          class="avatar rounded mr-3"
                          v-if="product.image"
                        />
                        <img
                          :src="dukaURL + 'dukamaket-content/avatar.png'"
                          :alt="product.product_title"
                          class="avatar rounded mr-3"
                          v-else
                        />
                      </td>
                      <td v-html="product.product_title"></td>
                      <td>{{ product.category_name }}</td>
                      <td class="table-actions text-right">
                        <a
                          class="btn btn-info btn-sm"
                          href="#"
                          title="Discount"
                          @click.prevent="
                            selectRecord(product);
                            falseObject(modalMode, 'discounting');
                            toggleModal();
                          "
                        >
                          <i class="ti-gift"></i>
                        </a>

                        <router-link
                          class="btn btn-primary btn-sm"
                          title="Preview"
                          :to="{
                            name: 'shopsingle',
                            params: {
                              category_slug: product.category_slug,
                              slug: product.product_slug
                            }
                          }"
                          target="_blank"
                        >
                          <i class="ti-eye"></i>
                        </router-link>
                        <a
                          class="btn btn-danger btn-sm"
                          href="#"
                          title="Delete Product"
                          @click.prevent="
                            selectRecord(product);
                            productDelete(choosenRow.product_title);
                          "
                        >
                          <i class="ti-trash"></i>
                        </a>
                      </td>
                      <td class="text-right">
                        <div class="dropdown show">
                          <a
                            class="btn btn-sm btn-icon-only text-light"
                            href="javascript:void(0)"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                          >
                            <i
                              class="ti-list text-primary"
                              style="font-size:18px"
                            ></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          >
                            <a
                              class="dropdown-item"
                              href="javascript:void(0)"
                              @click="
                                selectRecord(product);
                                falseObject(modalMode, 'priceEditing');
                                toggleModal();
                              "
                              >Edit Price</a
                            >
                            <a
                              class="dropdown-item"
                              href="javascript:void(0)"
                              @click.prevent="
                                selectRecord(product);
                                falseObject(modalMode, 'qtyEditing');
                                toggleModal();
                              "
                              >Edit quantity</a
                            >
                            <router-link
                              :to="{
                                name: 'editproduct',
                                params: { slug: product.product_slug }
                              }"
                              class="dropdown-item"
                              >Edit entire product</router-link
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr class="pb-4 mb-2" />
              <pagination
                :current_page="currentPage"
                :row_count_page="rowCountPage"
                @page-update="pageUpdate"
                :total_records="totalRows"
                :page_range="pageRange"
                prevIcon="ti-arrow-left"
                nextIcon="ti-arrow-right"
                dotsIcon="ti-more"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import MyPaginations from "@/components/Paginations.vue";
export default {
  name: "Products",
  components: {
    Datepicker,
    pagination: MyPaginations
  },
  data() {
    return {
      products: [],
      installments: [],
      current_installment: null,
      min_perc: null,
      min_cash: null,
      newPricing: {},
      modalMode: {
        installmentCreation: false,
        priceEditing: false,
        qtyEditing: false,
        discounting: false
      },
      newDiscount: {
        discount: null,
        discount_perc: null,
        start_date: null,
        expired_date: null
      },
      start_date: null,
      expired_date: null
    };
  },

  created() {
    this.responseRef = "products";
    this.paginationURL = "duka-root/show-all-products";
    this.loadPaginationRecords();
  },
  methods: {
    loadProducts() {
      this.$store
        .dispatch("dukaGetRequest", "duka-root/show-all-products")
        .then(response => {
          this.products = response.data.products;
        });
    },
    deleteProduct() {
      this.$store
        .dispatch(
          "dukaGetRequest",
          "duka-root/delete-product/" + this.choosenRow.id
        )
        .then(response => {
          console.log(response);
          this.loadPaginationRecords();
        });
    },
    toggleActions(action) {
      switch (action) {
        case "product_delete":
          this.deleteProduct();
          break;
        default:
          console.log("default data");
          break;
      }
      this.alertDefaults.modalOpen = false;
    },
    productDelete(product_title) {
      this.alertDefaults = {
        title: "Deleting a product",
        content:
          "Are you sure you want to delete this product: <b>" +
          product_title +
          "</b>",
        actionType: "product_delete",
        actionButton: "Yes, delete",
        classes: "btn btn-danger",
        modalOpen: true
      };
    },
    setDiscount() {
      let url = "duka-root/set-product-discount";
      this.newDiscount.start_date = this.start_date.toLocaleDateString();
      this.newDiscount.expired_date = this.expired_date.toLocaleDateString();
      this.newDiscount.product_id = this.choosenRow.id;
      var formData = this.formData(this.newDiscount);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.toggleModal();
          }
        })
        .catch(err => console.log(err));
    },
    calculateMinCash() {
      var orginal_price = Number(this.choosenRow.orginal_price);
      var min_perc = Number(this.min_perc);
      this.min_cash = (orginal_price * min_perc) / 100;
    },
    calculateMinPer() {
      var orginal_price = Number(this.choosenRow.orginal_price);
      var min_cash = Number(this.min_cash);
      this.min_perc = (min_cash * 100) / orginal_price;
    },
    calculateDiscount() {
      const rt = this.choosenRow.retail_price;
      const dc = this.newDiscount.discount_perc;
      this.newDiscount.discount = this.salePrice(rt, dc);
    },
    getCurrentInstallment() {
      this.current_installment = this.choosenRow.current_installment;
      this.min_perc = this.choosenRow.installment_min_perc;
      this.min_cash = this.choosenRow.installment_min_cash;
      this.falseObject(this.modalMode, "installmentCreation");
    },
    togglePrices(isPopUp = false) {
      this.calculateRetailPrice(isPopUp);
      this.calculateMemberPrice(isPopUp);
      this.calculateReturnPrice(isPopUp);
      this.calculateWholePrice(isPopUp);
      this.calculateBusinessPrice(isPopUp);
    },
    calculateRetailPrice(isPopUp = false) {
      var orginal_price = 0;
      var retail_perc = 0;
      if (isPopUp === true) {
        orginal_price = Number(this.choosenRow.orginal_price);
        retail_perc = Number(this.choosenRow.retail_perc);
        this.choosenRow.retail_price = this.salePriceT(
          orginal_price,
          retail_perc
        );
      }
    },
    calculateMemberPrice(isPopUp = false) {
      var orginal_price = 0;
      var member_perc = 0;
      if (isPopUp === true) {
        orginal_price = Number(this.choosenRow.orginal_price);
        member_perc = Number(this.choosenRow.member_perc);
        this.choosenRow.member_price = this.salePriceT(
          orginal_price,
          member_perc
        );
      }
    },

    calculateReturnPrice(isPopUp = false) {
      var orginal_price = 0;
      var return_perc = 0;
      if (isPopUp === true) {
        orginal_price = Number(this.choosenRow.orginal_price);
        return_perc = Number(this.choosenRow.return_perc);
        this.choosenRow.return_customer = this.salePriceT(
          orginal_price,
          return_perc
        );
      }
    },
    calculateWholePrice(isPopUp = false) {
      var orginal_price =
        isPopUp === true
          ? Number(this.choosenRow.orginal_price)
          : Number(this.newProduct.orginal_price);
      if (isPopUp === true) {
        var wholesale_perc = Number(this.choosenRow.wholesale_perc);
        this.choosenRow.wholesale_price = this.salePriceT(
          orginal_price,
          wholesale_perc
        );
      }
    },
    calculateBusinessPrice(isPopUp = false) {
      var orginal_price =
        isPopUp === true
          ? Number(this.choosenRow.orginal_price)
          : Number(this.newProduct.orginal_price);
      if (isPopUp === true) {
        var bb_perc = Number(this.choosenRow.bb_perc);
        this.choosenRow.bb_price = this.salePriceT(orginal_price, bb_perc);
      }
    },
    updateQuantity() {
      let newQty = {
        qty: this.choosenRow.quantity,
        id: this.choosenRow.master_id
      };
      let url = "duka-root/update-product-quantity";
      var formData = this.formData(newQty);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.toggleModal();
          }
        })
        .catch(err => console.log(err));
    },
    updatePrices() {
      let url = "duka-root/update-product-pricing";
      var formData = this.formData(this.choosenRow);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.toggleModal();
          }
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
<style lang="css">
.car-style > ul li {
  padding: 10px 0px 16px 8px;
  border: 1px solid rgb(209, 214, 219);
  background-color: rgb(255, 255, 255);
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  margin-top: 10px;
  font-weight: bolder;
}
.car-style > ul li.active,
.car-style > ul li:hover {
  border: 1px solid #041e42;
  background: #f1f2f9;
}

div.indicator {
  box-sizing: border-box;
  color: white;
  background-color: #041e42;

  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(209, 214, 219);
  border-image: initial;
  width: 19px;
  height: 19px;
  font-size: 12px;
  line-height: 19px;
  padding: 0px 3px;
  display: none;
}
.car-style > ul > li.active div.indicator,
.car-style > ul li:hover div.indicator {
  display: block;
}
.avatar.rounded-circle img,
.rounded-circle {
  border-radius: 50% !important;
}

.avatar {
  font-size: 1rem;
  display: inline-flex;
  width: 48px;
  height: 48px;
  color: #fff;
  border-radius: 0.375rem;
  background-color: #adb5bd;
  align-items: center;
  justify-content: center;
}

.avatar img {
  width: 100%;
  border-radius: 0.375rem;
}

.avatar + .avatar-content {
  display: inline-block;
  margin-left: 0.75rem;
}

.avatar-xl {
  width: 74px;
  height: 74px;
}

.avatar-lg {
  font-size: 0.875rem;
  width: 58px;
  height: 58px;
}

.avatar-sm {
  font-size: 0.875rem;
  width: 36px;
  height: 36px;
}

.avatar-xs {
  font-size: 0.75rem;
  width: 24px;
  height: 24px;
}

.avatar-group .avatar {
  position: relative;
  z-index: 2;
  border: 2px solid #fff;
}

.avatar-group .avatar:hover {
  z-index: 3;
}

.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}
.table-actions a {
  margin: auto 2px;
}
</style>
