<template>
  <div class="wrapper-page" style="max-width: 400px;">
    <div class="card border-0 rounded-0">
      <div class="card-body">
        <div class="text-center mb-0 d-flex">
          <a href="javascript:void(0)" class="logo logo-admin w-50 mx-auto">
            <img
              src="@/assets/logo-full.png"
              class="img img-fluid"
              alt="logo"
            />
          </a>
        </div>
        <div class="p-3 pt-0">
          <form class="form-horizontal" @submit.prevent="authenticateAdmin">
            <h5 class="text-center">Restricted Area</h5>
            <hr />
            <div
              class="alert alert-success alert-dismissible fade show"
              role="alert"
              v-if="loginSucceed"
            >
              <strong>Success!</strong> Login Success. Redirecting...
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="alert alert-danger" v-if="invalidLogin">
              <span>Invalid password or username</span>
            </div>
            <div class="form-group">
              <input
                class="form-control form-control-lg"
                name="username"
                type="text"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has('username') }"
                v-model="credentials.username"
                placeholder="Username"
              />
            </div>
            <div class="form-group">
              <input
                class="form-control form-control-lg"
                placeholder="Password"
                type="password"
                name="password"
                :class="{ 'is-invalid': errors.has('password') }"
                v-validate="'required'"
                v-model="credentials.password"
              />
            </div>

            <div class="form-group text-center row m-t-20">
              <div class="col-12">
                <button
                  class="btn btn-primary btn-block btn-lg waves-effect waves-light"
                  type="submit"
                >
                  Continue
                  <i
                    class="ion-arrow-right-c float-right"
                    style="font-size:20px"
                  ></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdminLogin",
  data() {
    return {
      credentials: {
        username: null,
        password: null
      },
      invalidLogin: false,
      loginSucceed: false,
      modalTest: true
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      window.location.replace("/admin/secret/dashboards");
    }
  },
  methods: {
    authenticateAdmin: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var formData = this.formData(this.credentials);
          this.$store
            .dispatch("logAdmin", formData)
            .then(response => {
              if (response.data.error) {
                this.invalidLogin = true;
                this.loginSucceed = false;
              } else {
                this.invalidLogin = false;
                this.loginSucceed = true;
                window.location.replace("/admin/secret/dashboards");
              }
            })
            .catch(err => console.log(err));
        }
      });
    }
  }
};
</script>
