<template>
  <div>
    <duka-alert
      :modalTitle="alertDefaults.title"
      :modalContent="alertDefaults.content"
      :actionButton="alertDefaults.actionButton"
      @accept-alert="toggleActions(alertDefaults.actionType)"
      @close="closeAlert"
      :actionButtonClasses="alertDefaults.classes"
      v-if="alertDefaults.modalOpen"
    />
    <duka-modal-form
      v-if="modalOpen"
      @close="toggleModal"
      style="display:block"
      :modalSize="'default'"
      :headerColor="'#000'"
    >
      <span slot="head" style="padding-top:10px">Add product category</span>
      <div slot="body">
        <div class="row">
          <div class="col-lg-12">
            <h4>
              {{ newCategory.id ? "Edit category" : "Create a new category" }}
            </h4>
            <hr />
          </div>
        </div>
        <div class="row align-items-center mt-2">
          <div class="col-lg-5">
            <div class="upload-box text-center">
              <p class="text-center" v-if="defaultCropImage">
                <img :src="defaultCropImage" class="img img-fluid" />
              </p>
              <div class="upload-container text-center" v-else>
                <h6 class="text-center w-100">Category Image</h6>
                <h6 class="text-center mb-2">
                  <span
                    class="img-thumbnail rounded-circle p-4 d-flex mx-auto"
                    style="width:90px; height:90px"
                  >
                    <span class="ti-upload font-40"></span>
                  </span>
                </h6>
                <button
                  type="button"
                  class="btn btn-sm btn-outline-primary"
                  @click="toggleSingleUpload"
                >
                  <span v-if="defaultCropImage">Edit main image</span>
                  <span v-else>Select an image</span>
                </button>
              </div>

              <my-upload
                field="duka_upload"
                @crop-success="dukaCropSuccess"
                @crop-upload-success="dukaUploadSuccess"
                v-model="showSingleUpload"
                :width="274"
                :height="225"
                :url="trashedImageUrl"
                lang-type="en"
                ref="singleUpload"
                img-format="png"
              />
            </div>
          </div>
          <div class="col-lg-7 my-2">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                v-model="newCategory.category_name"
                placeholder="Enter category name"
              />
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                style="height:auto!important"
                rows="4"
                v-model="newCategory.desciption"
                placeholder="Description(Optional)"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div slot="foot">
        <button
          type="button"
          class="btn btn-primary ml-auto"
          :disabled="!newCategory.category_name"
          @click="setCategory"
        >
          <span v-if="newCategory.id">Edit category</span>
          <span v-else>Add new category</span>
        </button>
      </div>
    </duka-modal-form>

    <div class="ant-transfer-list">
      <div class="ant-transfer-list-header">
        <span class="ant-transfer-list-header-selected">
          <span>{{ filteredCategories.length }} categories</span>
          <span class="ant-transfer-list-header-title">
            <button
              class="btn btn-primary btn-sm btn-icon-only rounded-circle"
              type="button"
              @click="
                clearObject(newCategory);
                toggleModal();
              "
              title="Add new"
            >
              <span class="btn-inner--icon">
                <i class="dripicons-plus"></i>
              </span>
            </button>
          </span>
        </span>
      </div>
      <div class="ant-transfer-list-body ant-transfer-list-body-with-search">
        <div class="ant-transfer-list-body-search-wrapper">
          <div>
            <input
              placeholder="Search here"
              type="text"
              v-model="searchKey"
              class="ant-input ant-transfer-list-search"
            />
            <span class="ant-transfer-list-search-action">
              <i class="anticon anticon-search">
                <svg
                  viewBox="64 64 896 896"
                  data-icon="search"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class
                >
                  <path
                    d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"
                  />
                </svg>
              </i>
            </span>
          </div>
        </div>
        <div class="ant-transfer-list-content">
          <ul class="list-group list-group-flush list my--1">
            <li
              class="list-group-item pl-2"
              v-for="(category, index) in filteredCategories"
              :key="'categoryTable' + index"
            >
              <div class="row align-items-center">
                <div
                  class="col-auto pr-1"
                  @click="showCurrentParentId(category)"
                >
                  <!-- Avatar -->
                  <a
                    href="javascript:void(0)"
                    class="avatar rounded bg-white"
                    style="width: 38px; height: 38px;"
                  >
                    <img
                      :alt="category.category_name"
                      :src="dukaURL + 'uploads/categories/' + category.image"
                      v-if="category.image"
                      class="img img-thumbnail rounded img-fluid"
                      style="width: 38px;"
                    />
                    <img
                      :alt="category.category_name"
                      class="img img-thumbnail rounded img-fluid"
                      :src="dukaURL + 'assets/upload-icon.png'"
                      style="width: 38px;"
                      v-else
                    />
                  </a>
                </div>
                <div class="col-7" @click="showCurrentParentId(category)">
                  <span class="mb-0">
                    <a href="javascript:void(0)">{{
                      category.category_name
                    }}</a>
                  </span>
                  <small>{{ category.description }}</small>
                </div>
                <div class="col-auto p-0 pr-1 text-right">
                  <button
                    class="btn btn-primary btn-sm btn-icon-only rounded-circle"
                    type="button"
                    @click="editCategory(category)"
                    title="Edit category"
                  >
                    <span class="btn-inner--icon">
                      <i class="dripicons-pencil"></i>
                    </span>
                  </button>
                  <button
                    class="btn btn-outline-danger btn-sm btn-icon-only rounded-circle"
                    type="button"
                    @click="
                      selectRecord(category);
                      categoryDelete(choosenRow.category_name);
                    "
                    title="Delete category"
                  >
                    <span class="btn-inner--icon">
                      <i class="dripicons-trash"></i>
                    </span>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myUpload from "vue-image-crop-upload";
export default {
  name: "DukaProductCategory",
  components: {
    "my-upload": myUpload
  },
  props: {
    parentId: {
      type: Number,
      required: true
    },
    childLevel: {
      type: Number,
      required: true
    },
    pathCode: null
  },
  data: () => ({
    newCategory: {
      parent_id: null,
      category_name: null,
      description: null,
      current_img: null
    },
    categories: [],
    searchKey: null
  }),
  created() {
    this.loadCategories();
    this.trashedImageUrl = this.trashedImageUrl + "/categories";
    this.defaultCropImage = null;
  },
  computed: {
    filteredCategories: function() {
      let searchKey = this.searchKey && this.searchKey.toLowerCase();
      let categories = this.categories;
      if (searchKey) {
        categories = categories.filter(row => {
          return Object.keys(row).some(key => {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(searchKey) > -1
            );
          });
        });
      }
      return categories;
    }
  },
  methods: {
    showCurrentParentId(category) {
      let newLevel = {
        id: category.id,
        level: this.childLevel,
        categoryName: category.category_name,
        slug: category.category_slug,
        pathCode: category.path_code
      };
      this.$emit("showparent", newLevel);
    },
    loadCategories() {
      let getURL = "duka-root/load-categories/" + this.parentId;
      this.$store.dispatch("dukaGetRequest", getURL).then(response => {
        this.categories = response.data.categories;
        this.clearObject(this.newCategory);
      });
    },
    setCategory() {
      this.newCategory.current_img = this.activeCroppedImage;
      if (!this.newCategory.id) {
        this.newCategory.parent_id = this.parentId;
        this.newCategory.level_index = Number(this.childLevel) - 1;
        this.newCategory.path_code = this.pathCode;
      }
      let url = "duka-root/set-category";
      var formData = this.formData(this.newCategory);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.handleFileReset();
            this.loadCategories();
            this.toggleModal();
          }
        })
        .catch(err => console.log(err));
    },
    deletecategory() {
      this.$store
        .dispatch(
          "dukaGetRequest",
          "duka-root/delete-category/" + this.choosenRow.id
        )
        .then(() => {
          this.loadCategories();
        });
    },
    toggleActions(action) {
      switch (action) {
        case "category_delete":
          this.deletecategory();
          break;
        default:
          //do nothing
          break;
      }
      this.alertDefaults.modalOpen = false;
    },
    editCategory(category) {
      this.newCategory = {
        id: category.id,
        category_name: category.category_name,
        description: category.description,
        current_img: category.image
      };
      if (category.image) {
        this.activeCroppedImage = category.image;
        this.defaultCropImage = this.dukaURL + "uploads/" + category.image;
      }
      this.toggleModal();
    },
    categoryDelete(category_name) {
      this.alertDefaults = {
        title: "Deleting a category",
        content:
          "Are you sure you want to delete this category:<b>" +
          category_name +
          " ?</b>",
        actionType: "category_delete",
        actionButton: "Yes, delete",
        classes: "btn btn-danger",
        modalOpen: true
      };
    }
  }
};
</script>
<style scoped>
body::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 0.6em;
}

body::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #134070;
  border-radius: 1px;
}
.ant-transfer-list {
  border: 1px solid #d9d9d9;
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
  position: relative;
  width: 100%;
  height: 350px;
  padding-top: 34px;
}
.ant-transfer {
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
}
.ant-transfer-list-header {
  padding: 12px;
  border-radius: 4px 4px 0 0;
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e8e8e8;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ant-checkbox-wrapper {
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: unset;
  cursor: pointer;
  display: inline-block;
}
.ant-checkbox {
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  top: -0.09em;
}
.ant-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
  border-collapse: separate;
}
.ant-checkbox-inner:after {
  transform: rotate(45deg) scale(0);
  position: absolute;
  left: 4.57142857px;
  top: 1.14285714px;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  opacity: 0;
}
.ant-checkbox-wrapper + span,
.ant-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-transfer-list-header-title {
  position: absolute;
  right: 2px;
  bottom: -5px;
}
.ant-transfer-list-body-with-search {
  padding-top: 52px;
}
.ant-transfer-list-body {
  font-size: 14px;
  position: relative;
  height: 100%;
}
.ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 10px;
  left: 0;
  padding: 4px;
  width: 100%;
}
.ant-transfer-list-search {
  padding: 0 8px;
}
.ant-input {
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 38px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-search-action {
  color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  width: 28px;
  line-height: 32px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.25);
}
.anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.anticon svg {
  display: inline-block;
}
svg:not(:root) {
  overflow: hidden;
}

.anticon > * {
  line-height: 1;
}
.ant-transfer-list-content {
  height: 100%;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}
.list-group-item {
  padding: 0.3rem 1rem;
  cursor: pointer;
  transition: all 0.3s;
}
.list-group-item:hover {
  background-color: #f5f5f5;
}
.btn-icon-only.btn-sm {
  width: 2rem;
  height: 2rem;
  line-height: 15px;
  padding-left: 8px;
  font-size: 16px;
}
.upload-container {
  border: 4px dashed #b4b9be;
  height: 200px;
}
</style>
