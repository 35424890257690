<template>
  <section class="py-4">
    <duka-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display:block"
      :modalSize="'default'"
    >
      <span slot="head" style="padding-top:10px"
        >Change your account password</span
      >
      <div slot="body">
        <div class="form-group">
          <label for>Current Password</label>
          <input
            type="password"
            v-validate="'required'"
            v-model="credentials.password"
            class="form-control"
            name="Current Password"
            :class="{ 'is-invalid': errors.has('Current Password') }"
            placeholder="Your current passsword"
          />
          <div class="invalid-feedback">
            {{ errors.first("Current Password") }}
          </div>
        </div>
        <div class="form-group">
          <label for>New Password</label>
          <input
            type="password"
            v-validate="'required'"
            name="password"
            :class="{ 'is-invalid': errors.has('password') }"
            ref="password"
            v-model="credentials.newPassword"
            class="form-control"
            placeholder="Type new password"
          />
          <div class="invalid-feedback">{{ errors.first("password") }}</div>
        </div>
        <div class="form-group">
          <label for>Confirm New Password</label>
          <input
            class="form-control"
            type="password"
            v-validate="'required|confirmed:password'"
            name="Password Confirmation"
            :class="{ 'is-invalid': errors.has('Password Confirmation') }"
            data-vv-as="password"
            v-model="credentials.passwordConf"
            placeholder="Re-type the new password"
          />
          <div class="invalid-feedback">
            {{ errors.first("Password Confirmation") }}
          </div>
        </div>
        <div class="w-100" v-if="invalidPassword">
          <div class="alert alert-danger">
            Invalid Current Password. Try again
          </div>
        </div>
        <div class="w-100" v-if="passwordChanged">
          <div class="alert alert-success">
            Your Password has been changed successfully.
          </div>
        </div>
      </div>

      <div slot="foot">
        <duka-button
          :loadText="'please wait...'"
          :caption="'Change Password'"
          @btn-role="changePassword"
          :classes="'btn btn-primary'"
          :disabler="!credentials.password"
          :activator="'settingaccountpassword'"
        />
      </div>
    </duka-modal>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-12">
          <div class="card jb-card">
            <div class="card-body">
              <div class="d-flex">
                <div class="mx-auto">
                  <h2 class="img-thumbnail p-2 rounded-circle">
                    <i class="dripicons-lock text-primary"></i>
                  </h2>
                </div>
              </div>
              <div class="text-center mt-1">
                <h6>Change your Account Password</h6>
                <button
                  class="btn btn-outline-primary mx-auto"
                  type="button"
                  @click="toggleModal"
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ChangePassword",
  data: () => ({
    currentUser: {},
    credentials: {
      newPassword: null,
      password: null,
      passwordConf: null
    },
    invalidPassword: false,
    passwordChanged: false
  }),
  methods: {
    changePassword() {
      var formData = this.formData(this.credentials);
      let url = "duka-root/change-account-password";
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.invalid_password) {
            this.invalidPassword = false;
            this.passwordChanged = true;
            this.clearObject(this.credentials);
          } else {
            this.invalidPassword = true;
          }
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
