<template>
  <section class="pt-4">
    <duka-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display:block"
      :modalSize="'default'"
      :headerColor="'#000'"
    >
      <span slot="head" style="padding-top:10px"
        >Create Pricing of this variation</span
      >
      <div slot="body">
        <div class="form-group row">
          <div class="col-12">
            <label class="w-100">Regular Price</label>
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                <span class="input-group-text">RWF</span>
              </div>
              <input
                class="form-control"
                type="text"
                v-model="choosenVariant.orginal_price"
                @keyup="togglePrices(true)"
                placeholder="0.0"
              />
            </div>
          </div>

          <div class="col-12 mt-3">
            <label>Sale Price(%)</label>
            <div class="row">
              <div class="col-4">
                <div class="input-group input-group-merge">
                  <input
                    class="form-control"
                    type="text"
                    v-model="choosenVariant.retail_perc"
                    @keyup="calculateRetailPrice(true)"
                    placeholder="0.0"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <b>%</b>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div class="input-group input-group-merge">
                  <div class="input-group-prepend">
                    <span class="input-group-text">RWF</span>
                  </div>
                  <input
                    class="form-control"
                    type="text"
                    v-model="choosenVariant.retail_price"
                    placeholder="0.0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="foot">
        <button
          type="button"
          class="btn btn-primary mt--4 float-right"
          :disabled="!choosenVariant.retail_price"
          @click="toggleModal"
        >
          Confirm pricing
        </button>
      </div>
    </duka-modal>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-10 mx-auto">
          <div class="card">
            <div class="card-body border-bottom">
              <div class="row align-items-center">
                <div class="col-8">
                  <h4 class="mb-0">Create a new product</h4>
                </div>
                <div class="col-4 text-right">
                  <!-- <a href="profile.html#!" class="btn btn-sm btn-primary">Settings</a> -->
                </div>
              </div>
            </div>
            <div class="card-body">
              <form-wizard
                @on-complete="editProduct"
                color="#041e42"
                shape="tab"
                v-bind:title="formTitle"
                v-bind:subtitle="formSubtitle"
              >
                <tab-content title="Basic Information" icon="dripicons-list">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label>Product title:</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="newProduct.product_title"
                            placeholder="Product Name"
                          />
                        </div>
                        <div class="col-sm-4" v-if="false">
                          <label>Product data:</label>
                          <select
                            class="form-control custom-select"
                            v-model="newProduct.product_type"
                          >
                            <option value="null" hidden disabled
                              >Product type</option
                            >
                            <option value="Simple">Simple product</option>
                            <option value="Variable">Variabe product</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label
                              >Product tags or keywords for search engine
                              optimization</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              v-model="newProduct.product_tags"
                              placeholder="Enter product search engine keywords"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-12">
                          <h5>Product description</h5>
                          <textarea
                            name="editor"
                            v-model="newProduct.description"
                            rows="4"
                            class="form-control"
                            style="height:auto!important"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </tab-content>

                <tab-content title="Inventory" icon="ti-view-list" v-if="false">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="widget-title">Dimensions & Weight</h5>
                      <p>
                        Enter the dimensions and weight of this product to help
                        calculate shipping rate. These measurements are for the
                        product's shipping container. They are used to help
                        calculate shipping price.
                      </p>
                      <div class="form-group row">
                        <div class="col-sm-3">
                          <input
                            type="text"
                            placeholder="Weight"
                            class="form-control"
                            v-model="newProduct.weight_d"
                          />
                        </div>
                        <div class="col-sm-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Length"
                            v-model="newProduct.length_d"
                          />
                        </div>
                        <div class="col-sm-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Width"
                            v-model="newProduct.width_d"
                          />
                        </div>
                        <div class="col-sm-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Height"
                            v-model="newProduct.height_d"
                          />
                        </div>
                      </div>

                      <h5 class="widget-title">
                        Product Inventory information
                      </h5>
                      <div class="form-group row">
                        <div class="col-sm-3">
                          <input
                            type="text"
                            v-model="newProduct.quantity"
                            class="form-control"
                            placeholder="Stock Quantity"
                          />
                        </div>

                        <div class="col-sm-3">
                          <input
                            type="text"
                            v-model="newProduct.min_quantity"
                            class="form-control"
                            placeholder="Min. Purchasable Qty"
                          />
                        </div>
                        <div class="col-sm-3">
                          <input
                            type="text"
                            v-model="newProduct.max_quantity"
                            class="form-control"
                            value="0"
                            placeholder="Max. Purchase Qty"
                          />
                        </div>
                      </div>
                      <div class="form-group row" v-if="false">
                        <div class="col-sm-4">
                          <label for>Allow backorders</label>
                          <div class="btn-group w-100" role="group">
                            <label
                              class="btn btn-secondary"
                              :class="{ active: newProduct.backorder === 0 }"
                            >
                              <input
                                type="radio"
                                name="backorder"
                                id="option1b"
                                v-model="newProduct.backorder"
                                value="0"
                              />
                              No
                            </label>
                            <label
                              class="btn btn-secondary"
                              :class="{ active: newProduct.backorder === 1 }"
                            >
                              <input
                                type="radio"
                                name="backorder"
                                id="option3b"
                                v-model="newProduct.backorder"
                                value="1"
                              />
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </tab-content>

                <tab-content title="Attributes" icon="ti-check" v-if="false">
                  <template>
                    <div
                      class="row align-items-center"
                      v-if="currentAttributes.length"
                    >
                      <div
                        class="form-group col-3 mb-3"
                        v-for="(field, j) in currentAttributes"
                        :key="'currentAttributes' + j"
                      >
                        <label>{{ field.label }}</label>
                        <div>
                          <select
                            class="form-control custom-select"
                            v-model="
                              attributesValues[Object.keys(attributesValues)[j]]
                            "
                          >
                            <option value="null" hidden selected
                              >Select {{ field.label }}</option
                            >
                            <option
                              :value="item"
                              v-for="(item, key) in explodeString(
                                ',',
                                field.items
                              )"
                              :key="key"
                              >{{ item }}</option
                            >
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="text-center">
                      <h5>
                        Create your custom products attributes or features
                      </h5>
                      <button
                        class="btn btn-sm btn-outline-primary"
                        type="button"
                        @click="createCustomField"
                        v-if="!customFields.length"
                      >
                        Add Custom Fields
                      </button>
                    </div>
                    <div
                      class="row align-items-center my-3"
                      v-for="(field, index) in customFields"
                      :key="'customField' + index"
                    >
                      <div class="col-sm-4">
                        <label>Feature name</label>
                        <input
                          type="text"
                          placeholder="Eg: Wine Vintage"
                          v-model="customFields[index].name"
                          class="form-control"
                        />
                      </div>
                      <div class="col-sm-4">
                        <label>Feature value</label>
                        <input
                          type="text"
                          placeholder="1980"
                          v-model="customFields[index].value"
                          class="form-control"
                        />
                      </div>
                      <div class="col-sm-2 pt-4">
                        <a
                          class="btn btn-sm btn-outline-danger rounded-circle btn-icon-sm mt-2"
                          href="javascript:void(0)"
                          @click.prevent="removeCustomField(index)"
                          style="cursor:pointer;"
                          title="Delete Feature"
                        >
                          <span class="dripicons-minus"></span>
                        </a>
                      </div>
                    </div>
                    <div class="row mt-2" v-if="customFields.length">
                      <div class="col-12">
                        <a
                          class="text-primary"
                          href="javascript:void(0)"
                          @click.prevent="createCustomField"
                        >
                          <span class="dripicons-plus"></span>
                          Add Custom Fields
                        </a>
                      </div>
                    </div>
                  </template>
                </tab-content>
                <tab-content
                  v-if="newProduct.product_type === 'Variable'"
                  title="Variations"
                  icon="ti-settings"
                  :before-change="generatePricing"
                >
                  <div>
                    <h5>Variations options</h5>
                    <div class="text-center" v-if="!currentVariations.length">
                      <span class="text-mutted"
                        >No Option has been added yet.</span
                      >
                      <br />
                      <button
                        class="btn btn-sm btn-outline-primary mt-2"
                        type="button"
                        @click="createVariation"
                      >
                        <span class="btn-inner--icon">
                          <i class="dripicons-plus"></i>
                        </span>
                        <span class="btn-inner--text">Add variant options</span>
                      </button>
                    </div>
                    <div
                      class="row mt-4 mb-4 pb-4"
                      style="border-bottom: 1px solid #dee2e6;"
                      v-for="(variation, indexx) in currentVariations"
                      :key="'variations' + indexx"
                    >
                      <div class="col-sm-3">
                        <label for>Name</label>
                        <input
                          type="text"
                          placeholder="Eg: color, size, etc..."
                          class="form-control"
                          v-model="currentVariations[indexx].variation_name"
                        />
                        <a
                          class="text-danger"
                          href="javascript:void(0)"
                          @click.prevent="RemoveVariotion(indexx)"
                          >Delete option</a
                        >
                      </div>
                      <div class="col-sm-3">
                        <label for>Type</label>
                        <select
                          class="form-control custom-select"
                          v-model="currentVariations[indexx].variation_type"
                        >
                          <option value="null" disabled hidden
                            >Select type</option
                          >
                          <option value="radio">Radio Button</option>
                          <option value="dropdown">Drop down</option>
                          <option value="rectangular">Rectangular List</option>
                          <option value="images">Images</option>
                        </select>
                      </div>
                      <div
                        class="col-sm-6"
                        v-if="
                          currentVariations[indexx].variation_type !== 'images'
                        "
                      >
                        <label for>Values</label>
                        <div
                          class="row mb-3 align-items-center"
                          v-for="(values, indexxx) in currentVariations[indexx]
                            .variation_values"
                          :key="'variation_values' + indexxx"
                        >
                          <div class="col-sm-7">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter value here"
                              v-model="
                                currentVariations[indexx].variation_values[
                                  indexxx
                                ].valuee
                              "
                            />
                          </div>
                          <div class="col-sm-3">
                            <div class="custom-control custom-radio my-2">
                              <input
                                value="1"
                                v-model="
                                  currentVariations[indexx].variation_values[
                                    indexxx
                                  ].isDefault
                                "
                                :name="'isdefault' + indexx"
                                class="custom-control-input"
                                :id="'customRadio6' + indexx + indexxx"
                                type="radio"
                              />
                              <label
                                class="custom-control-label"
                                :for="'customRadio6' + indexx + indexxx"
                                >Default</label
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <button
                              class="btn btn-sm btn-icon-sm rounded-circle btn-outline-danger"
                              v-if="
                                currentVariations[indexx].variation_values
                                  .length > 1
                              "
                              type="button"
                              @click="RemoveVariationValuee(indexx, indexxx)"
                            >
                              <i class="dripicons-minus"></i>
                            </button>
                          </div>
                        </div>
                        <button
                          type="button"
                          @click="createVariationValuee(indexx)"
                          class="btn btn-primary btn-sm mt-2"
                        >
                          <i class="dripicons-plus"></i> Add new value
                        </button>
                      </div>
                      <div class="col-sm-6" v-else>
                        <h6>Images</h6>
                        <div
                          class="col-lg-6"
                          v-for="(values, indexxx) in currentVariations[indexx]
                            .variation_values"
                          :key="'variation_values' + indexxx"
                        >
                          <div v-if="variationsThumbs[indexxx]">
                            <div
                              class="jb-gallery-upload text-center py-1"
                              style="height:4rem!important"
                            >
                              <img
                                :src="variationsThumbs[indexxx]"
                                class="img img-thumbails rounded img-fluid"
                              />
                              <a
                                class="text-danger mt-2 w-100"
                                href="javascript:void(0)"
                                @click.prevent="
                                  handleThumbReset(indexx, indexxx)
                                "
                                >Delete</a
                              >
                            </div>
                          </div>

                          <div
                            class="jb-gallery-upload"
                            v-else
                            @click="singleWolfUpload(indexx, indexxx)"
                            style="height:4rem!important"
                          >
                            <p class="text-center">
                              <img
                                :src="dukaURL + 'assets/upload-icon.png'"
                                class="img img-fluid"
                              />
                            </p>
                          </div>

                          <wolf-upload
                            :ref="'variationThumb' + indexx + indexxx"
                            @preview="handleThumbPreview"
                            :inputId="'variationThumbId' + indexx + indexxx"
                            @after-uploading="keepUploadedThumb"
                            uploadURL="ap/variations-thumbs"
                            :extentions="['png', 'jpg', 'jpeg', 'gif']"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="currentVariations.length">
                      <button
                        class="btn btn-sm btn-outline-primary mt-2"
                        type="button"
                        @click="createVariation()"
                      >
                        <span class="btn-inner--icon">
                          <i class="dripicons-plus"></i>
                        </span>
                        <span class="btn-inner--text">Add variant options</span>
                      </button>
                    </div>
                  </div>
                </tab-content>

                <tab-content title="Pricing" icon="dripicons-card">
                  <template
                    v-if="
                      newProduct.product_type === 'Variable' && varCols.length
                    "
                  >
                    <h5 class="mb-0">Variation Pricing</h5>
                    <p>
                      Create pricing of each variant you have and make sure to
                      delete variants that don't exist in your stock for better
                      search engine optimization.
                    </p>

                    <div class="table-responsive">
                      <table
                        class="table table-striped align-items-center table-flush"
                      >
                        <thead class="thead-light">
                          <tr>
                            <th class="text-center">#</th>
                            <th></th>
                            <th
                              class="text-center"
                              v-for="(col, i) in varCols"
                              :key="'Pricecol' + i"
                            >
                              {{ col }}
                            </th>
                            <th style="width:20%">Stock Qty</th>
                            <th>Pricing</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, i) in cartesians" :key="'row' + i">
                            <td class="text-center">
                              #{{ incrementIndex(i) }}
                            </td>
                            <td>
                              <img
                                :src="rows[i].image_url"
                                class="img img-thumbnail rounded img-fuid"
                                style="width:60px; height:60px"
                                v-if="rows[i].image_url"
                              />
                              <img
                                :src="dukaURL + 'assets/upload-avatar.png'"
                                class="img img-thumbnail rounded img-fuid"
                                style="width:40px; height:40px"
                                v-else
                              />
                            </td>
                            <td
                              class="text-center"
                              v-for="(col, j) in varCols"
                              :key="'PriceRow' + j"
                            >
                              {{ row[j] }}
                            </td>
                            <td style="width:20%">
                              <input
                                type="text"
                                v-model="rows[i].stock_qty"
                                placeholder="Enter quantity"
                                class="form-control form-control-sm"
                              />
                            </td>
                            <td>
                              <span
                                class="badge badge-dot mr-4"
                                v-if="rows[i].orginal_price"
                              >
                                <i class="bg-success"></i>
                                <span class="text-success">Price Set</span>
                              </span>
                              <span class="badge badge-dot mr-4" v-else>
                                <i class="bg-warning"></i>
                                <span class="text-warning">Not set</span>
                              </span>
                            </td>
                            <td class="table-actions">
                              <a
                                href="javascript:void(0)"
                                @click.prevent="
                                  selectVariation(rows[i]);
                                  toggleModal();
                                "
                                class="table-action mr-2"
                                title="Set variation price"
                              >
                                <i class="dripicons-tags text-primary"></i>
                              </a>
                              <a
                                href="javascript:void(0)"
                                @click.prevent="removePricingRow(i)"
                                class="table-action table-action-delete"
                                title="Remove variation"
                              >
                                <i class="dripicons-cross text-danger"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                  <template v-else>
                    <div class="row">
                      <div class="col-7 mx-auto">
                        <div class="card">
                          <div class="card-body">
                            <h5 class="widget-title">Product Pricing</h5>
                            <div class="form-group row">
                              <label for class="col-sm-12">Regular price</label>
                              <div class="col-sm-12">
                                <div class="input-group input-group-merge">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">RWF</span>
                                  </div>
                                  <input
                                    class="form-control"
                                    type="text"
                                    v-model="newProduct.orginal_price"
                                    @keyup="togglePrices()"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-sm-12">
                                <label>Sell Price(%)</label>
                                <div class="row">
                                  <div class="col-4 pr-0">
                                    <div class="input-group input-group-merge">
                                      <input
                                        class="form-control"
                                        type="text"
                                        v-model="newProduct.retail_perc"
                                        @keyup="calculateRetailPrice()"
                                      />
                                      <div class="input-group-append">
                                        <span class="input-group-text">
                                          <b>%</b>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-8">
                                    <div class="input-group input-group-merge">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text"
                                          >RWF</span
                                        >
                                      </div>
                                      <input
                                        class="form-control"
                                        type="text"
                                        v-model="newProduct.retail_price"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </tab-content>

                <tab-content title="Product Gallery" icon="ti-check">
                  <div class="row align-items-center">
                    <div class="col-lg-3">
                      <h6>Product's Image</h6>
                      <div v-if="productImage">
                        <div
                          class="jb-gallery-upload text-center py-1"
                          style="height:auto!important"
                        >
                          <img
                            :src="dukaURL + 'uploads/' + productImage"
                            class="img img-thumbails rounded img-fluid"
                          />
                          <a
                            class="text-danger mt-2 w-100"
                            href="javascript:void(0)"
                            @click.prevent="handleImageDelete"
                            >Delete image</a
                          >
                        </div>
                      </div>

                      <div
                        class="jb-gallery-upload"
                        v-else
                        @click="toggleSingleUpload"
                      >
                        <p class="text-center">
                          <img
                            :src="dukaURL + 'assets/upload-icon.png'"
                            class="img img-fluid"
                          />
                        </p>
                        <p class="text-mutted text-center">
                          Click to add main image here to upload
                        </p>
                      </div>

                      <my-upload
                        field="duka_upload"
                        @crop-success="dukaCropSuccess"
                        @crop-upload-success="wolfUploadSuccess"
                        v-model="showSingleUpload"
                        :width="200"
                        :height="200"
                        :url="trashedImageUrl"
                        lang-type="en"
                        ref="singleUpload"
                        img-format="png"
                      />
                    </div>
                    <div class="col-lg-9">
                      <h6>Product Gallery</h6>

                      <my-upload
                        field="duka_upload"
                        @crop-success="dukaGalleryImage"
                        @crop-upload-success="wolfGalleryUpload"
                        v-model="showGalleryUpload"
                        :width="200"
                        :height="200"
                        :url="trashedImageUrl"
                        lang-type="en"
                        ref="galleryUpload"
                        img-format="png"
                      />

                      <template v-if="productGallery.length">
                        <div
                          class="row border-bottom py-2 align-items-center"
                          v-for="(tempImg, key) in productGallery"
                          :key="'gallery' + key"
                        >
                          <div class="col-2 pr-1">
                            <img
                              :src="dukaURL + 'uploads/' + tempImg"
                              class="img img-thumbnail rounded img-fluid"
                            />
                          </div>
                          <div class="col-3 px-1">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="add alt text"
                            />
                          </div>
                          <div class="col-6 pl-1">
                            <textarea
                              type="text"
                              class="form-control"
                              placeholder="description"
                            />
                          </div>
                          <div class="col-1 pt-2 px-0">
                            <a
                              href="#"
                              @click.prevent="handleGalleryReset(key, tempImg)"
                              class="btn btn-sm btn-outline-danger"
                            >
                              <span class="dripicons-trash"></span>
                            </a>
                          </div>
                        </div>
                        <div
                          class="jb-gallery-upload mt-2"
                          style="height:30px;"
                          @click="toggleGalleryUpload"
                        >
                          <p class="text-center">
                            <img
                              :src="dukaURL + 'assets/upload-icon.png'"
                              class="img img-fluid"
                              style="width: 75%;"
                            />
                          </p>
                          <p class="text-mutted mb-0">
                            Add more images into product's gallery
                          </p>
                        </div>
                      </template>
                      <div
                        class="jb-gallery-upload"
                        v-else
                        @click="toggleGalleryUpload"
                      >
                        <p class="text-center mb-0">
                          <img
                            :src="dukaURL + 'assets/upload-icon.png'"
                            class="img img-fluid"
                          />
                        </p>
                        <p class="text-mutted">
                          No images available in the product gallery now. <br />
                          Click to add images into product's gallery
                        </p>
                      </div>
                    </div>
                  </div>
                </tab-content>
              </form-wizard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import myUpload from "vue-image-crop-upload";
import WolfUpload from "@/components/WolfUpload";
export default {
  name: "ProductAdd",
  components: {
    "my-upload": myUpload,
    WolfUpload
  },
  data() {
    return {
      product: {},
      attributes: {},
      brands: {},
      isProduct: true,
      newProduct: {
        product_id: null,
        product_type: "Variable",
        inventory_type: "stock",
        product_condition: "New",
        brand: null,
        sku: null,
        category_id: 0,
        product_title: null,
        product_tags: null,
        description: null,
        quantity: null,
        orginal_price: 0,
        retail_price: 0,
        retail_perc: 0,
        member_price: 0,
        member_perc: 0,
        return_customer: 0,
        return_perc: 0,
        whole_price: 0,
        whole_perc: 0,
        b_price: 0,
        b_perc: 0,
        whole_min: 0,
        b_min: 0,
        low_stock: 0,
        backorder: 1,
        sold_individual: 0,
        weight_d: null,
        length_d: null,
        width_d: null,
        height_d: null,
        shipping_id: 1,
        image: null,
        min_quantity: null,
        max_quantity: null,
        gallery: [],
        attributes: [],
        variations: [],
        prices: [],
        features: [],
        requiredMemberPrice: false,
        requiredReturningPrice: false,
        requiredWholePrice: false,
        requiredBusinessPrice: false
      },
      currentVariations: [],
      currentAttributes: [],
      customFields: [],
      attributesValues: {},
      rows: [],
      cols: [],
      cartesians: [],
      varCols: [],
      choosenVariant: {},
      variantsGranted: false,
      customField: null,
      variationsThumbs: [],
      activeThumbIndex1: 0,
      activeThumbIndex2: 0,
      productImage: null,
      productGallery: []
    };
  },
  created() {
    this.getproductData();
  },
  methods: {
    createVariation() {
      this.currentVariations.push({
        variation_name: null,
        variation_type: null,
        variation_values: [{ valuee: null, isDefault: 0 }]
      });
    },
    RemoveVariotion(key) {
      this.currentVariations.splice(key, 1);
    },
    createVariationValuee(key) {
      this.currentVariations[key].variation_values.push({
        valuee: null,
        isDefault: 0
      });
    },
    RemoveVariationValuee(indexa, key) {
      this.currentVariations[indexa].variation_values.splice(key, 1);
    },
    createCustomField() {
      this.customFields.push({ name: null, value: null });
      // if (this.customField) {
      //   this.customFields.push(this.customField);
      //   this.customField = null;
      // }
    },
    removeCustomField(key) {
      this.customFields.splice(key, 1);
    },
    editProduct() {
      this.newProduct.image = this.productImage;
      this.newProduct.gallery = JSON.stringify(this.defaultCropGallery);
      if (this.newProduct.product_type === "Variable") {
        this.newProduct.prices = JSON.stringify(this.rows);
        this.newProduct.variations = JSON.stringify(this.currentVariations);
      }
      this.newProduct.attributes = JSON.stringify(this.customFields);
      let url = "duka-root/edit-product";
      var formData = this.formData(this.newProduct);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            window.location.replace("/admin/secret/products-listing");
          }
        })
        .catch(err => console.log(err));
    },
    getproductData() {
      this.$store
        .dispatch(
          "dukaGetRequest",
          "duka-root/product-single/" + this.$route.params.slug
        )
        .then(response => {
          this.product = response.data.product;
          let product = response.data.product;
          let pricing = response.data.product.pricing;
          let shipping = response.data.product.shipping;
          this.newProduct.product_type = product.product_type;
          this.newProduct.product_id = product.id;
          this.newProduct.product_condition = product.product_condition;
          this.newProduct.product_title = product.product_title;
          this.newProduct.product_tags = product.product_tags;
          this.newProduct.description = product.description;
          this.newProduct.quantity = product.quantity;
          this.newProduct.orginal_price = pricing.orginal_price;
          this.newProduct.retail_perc = pricing.retail_price;
          this.newProduct.member_perc = pricing.member_price;
          this.newProduct.return_customer = pricing.return_customer;
          this.newProduct.whole_price = pricing.wholesale_price;
          this.newProduct.low_stock = product.low_stock;
          this.newProduct.backorder = product.backorder;
          this.newProduct.sold_individual = product.sold_individual;
          this.newProduct.weight_d = shipping.weight_d;
          this.newProduct.length_d = shipping.length_d;
          this.newProduct.width_d = shipping.width_d;
          this.newProduct.height_d = shipping.height_d;
          this.newProduct.image = product.image;
          this.newProduct.min_quantity = product.min_qty;
          this.newProduct.max_quantity = product.max_qty;
          //if (product.attributes) this.customFields = product.attributes;
          if (product.variations) this.currentVariations = product.variations;
          if (product.variation_prices) this.rows = product.variation_prices;
          if (product.gallery && product.gallery[0] != "")
            this.productGallery = product.gallery;
          this.productImage = product.image;
        });
    },
    togglePrices(isPopUp = false) {
      this.calculateRetailPrice(isPopUp);
      this.calculateMemberPrice(isPopUp);
      this.calculateReturnPrice(isPopUp);
      this.calculateWholePrice(isPopUp);
      this.calculateBusinessPrice(isPopUp);
    },
    calculateRetailPrice(isPopUp = false) {
      var orginal_price = 0;
      var retail_perc = 0;
      if (isPopUp === true) {
        orginal_price = Number(this.choosenVariant.orginal_price);
        retail_perc = Number(this.choosenVariant.retail_perc);
        this.choosenVariant.retail_price = this.salePrice(
          orginal_price,
          retail_perc
        );
      } else {
        orginal_price = Number(this.newProduct.orginal_price);
        retail_perc = Number(this.newProduct.retail_perc);
        this.newProduct.retail_price = this.salePrice(
          orginal_price,
          retail_perc
        );
      }
    },
    calculateMemberPrice(isPopUp = false) {
      var orginal_price = 0;
      var member_perc = 0;
      if (isPopUp === true) {
        orginal_price = Number(this.choosenVariant.orginal_price);
        member_perc = Number(this.choosenVariant.member_perc);
        this.choosenVariant.member_price = this.salePrice(
          orginal_price,
          member_perc
        );
      } else {
        orginal_price = Number(this.newProduct.orginal_price);
        member_perc = Number(this.newProduct.member_perc);
        this.newProduct.member_price = this.salePrice(
          orginal_price,
          member_perc
        );
      }
    },

    calculateReturnPrice(isPopUp = false) {
      var orginal_price = 0;
      var return_perc = 0;
      if (isPopUp === true) {
        orginal_price = Number(this.choosenVariant.orginal_price);
        return_perc = Number(this.choosenVariant.return_perc);
        this.choosenVariant.return_customer = this.salePrice(
          orginal_price,
          return_perc
        );
      } else {
        orginal_price = Number(this.newProduct.orginal_price);
        return_perc = Number(this.newProduct.return_perc);
        this.newProduct.return_customer = this.salePrice(
          orginal_price,
          return_perc
        );
      }
    },
    calculateWholePrice(isPopUp = false) {
      var orginal_price =
        isPopUp === true
          ? Number(this.choosenVariant.orginal_price)
          : Number(this.newProduct.orginal_price);
      if (isPopUp === true) {
        var wholesale_perc = Number(this.choosenVariant.wholesale_perc);
        this.choosenVariant.wholesale_price = this.salePrice(
          orginal_price,
          wholesale_perc
        );
      } else {
        var whole_perc = Number(this.newProduct.whole_perc);
        this.newProduct.whole_price = this.salePrice(orginal_price, whole_perc);
      }
    },
    calculateBusinessPrice(isPopUp = false) {
      var orginal_price =
        isPopUp === true
          ? Number(this.choosenVariant.orginal_price)
          : Number(this.newProduct.orginal_price);
      if (isPopUp === true) {
        var bb_perc = Number(this.choosenVariant.bb_perc);
        this.choosenVariant.bb_price = this.salePrice(orginal_price, bb_perc);
      } else {
        var b_perc = Number(this.newProduct.b_perc);
        this.newProduct.b_price = this.salePrice(orginal_price, b_perc);
      }
    },
    addCommaPrice(num) {
      this[num] = this.addComma(num);
    },
    generatePricing() {
      if (this.newProduct.product_type !== "Simple") {
        let values = [];
        var arr = this.currentVariations;
        arr.forEach(arrItem => {
          let tempArr = [];
          arrItem.variation_values.forEach(elt => {
            tempArr.push(elt.valuee);
          });
          values.push(tempArr);
          this.varCols = [];
          this.varCols.push(arrItem.variation_name);
        });

        this.cartesians = this.cartesianProduct(values);
        this.cartesians.forEach(arrItem => {
          var temp = {};
          for (var i = 0; i < arr.length; i++) {
            temp[arr[i].variation_name] = arrItem[i];
          }
          temp.orginal_price = null;
          temp.member_price = null;
          (temp.member_perc = null), (temp.wholesale_price = null);
          temp.wholesale_perc = null;
          temp.bb_price = null;
          temp.bb_perc = null;
          temp.wholesale_qty = null;
          temp.stock_qty = null;
          temp.bb_qty = null;
          temp.retail_price = null;
          (temp.retail_perc = null),
            (temp.return_customer = null),
            (temp.return_perc = null),
            (temp.requiredMemberPrice = false),
            (temp.requiredReturningPrice = false),
            (temp.requiredWholePrice = false),
            (temp.requiredBusinessPrice = false),
            this.rows.push(temp);
        });
      }
      return true;
    },
    removePricingRow(key) {
      this.rows.splice(key, 1);
      this.cartesians.splice(key, 1);
    },
    selectVariation(variant) {
      this.choosenVariant = variant;
    },
    singleWolfUpload(index1, index2) {
      this.activeThumbIndex1 = index1;
      this.activeThumbIndex2 = index2;
      let refName = "variationThumb" + index1 + index2;
      this.$refs[refName].handleFilePickUp();
    },
    keepUploadedThumb(filename) {
      this.currentVariations[this.activeThumbIndex1].variation_values[
        this.activeThumbIndex2
      ].valuee = filename;
    },
    handleThumbPreview(imgDataURL) {
      this.variationsThumbs[this.activeThumbIndex2] = imgDataURL;
    },
    wolfUploadSuccess(jsonData, field) {
      this.productImage = jsonData.filename;
      return field;
    },
    handleImageDelete() {
      this.$store
        .dispatch(
          "dukaGetRequest",
          "duka-root/delete-product-image/" +
            this.product.id +
            "/" +
            this.productImage
        )
        .then(() => {
          this.productImage = null;
        });
    },
    handleGalleryReset(key, filename) {
      this.$store
        .dispatch(
          "dukaGetRequest",
          "duka-root/delete-product-gallery/" + this.product.id + "/" + filename
        )
        .then(() => {
          if (this.defaultCropGallery.includes(filename)) {
            this.dukaGallery.splice(key, 1);
            this.defaultCropGallery.splice(key, 1);
          }
          this.productGallery.splice(key, 1);
        });
    },
    wolfGalleryUpload(jsonData, field) {
      this.defaultCropGallery.push(jsonData.filename);
      this.productGallery.push(jsonData.filename);
      return field;
    }
  }
};
</script>
<style lang="css">
.btn-icon-sm {
  width: 1.5rem;
  height: 1.5rem;
  padding: 2px 4px !important;
  line-height: 20px !important;
}
.jb-gallery-upload {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px dashed #041e42;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 12rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 3.2rem 0.8rem;
  width: 100%;
}
</style>
