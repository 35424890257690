<template>
  <section class="py-4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-10">
          <div class="page-title-box">
            <div class="btn-group float-right">
              <ol class="breadcrumb hide-phone p-0 m-0">
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)">Company</a>
                </li>
                <li class="breadcrumb-item active">Withdraw requests</li>
              </ol>
            </div>
            <h4 class="page-title">Dashboard</h4>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="card">
            <div class="card-body border-bottom">
              <h4>
                Companies withdraw Requests
              </h4>
            </div>
            <div class="card-body">
              <div class="table-responsive table-responsive-sm">
                <table class="table">
                  <thead>
                    <tr>
                      <th>$/N</th>
                      <th>Transaction code</th>
                      <th>Company</th>
                      <th>Request date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(request, index) in requests"
                      :key="'request' + index"
                    >
                      <td>{{ incrementIndex(index) }}</td>
                      <td>{{ request.withdraw_code }}</td>
                      <td>{{ request.company_name }}</td>
                      <td>{{ request.request_date }}</td>
                      <td>{{ addComma(request.amount_requested) }}</td>
                      <td>
                        <span
                          class="badge badge-boxed badge-soft-warning"
                          v-if="!Number(request.granted)"
                          >Pending</span
                        >
                        <span
                          class="badge badge-boxed badge-soft-success"
                          v-else
                          >Completed</span
                        >
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-sm btn-success"
                          v-if="!Number(request.granted)"
                          @click="closeRequest(request.id)"
                        >
                          <i class="dripicons-checkmark"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Requests",
  data: () => ({
    requests: []
  }),
  created() {
    this.loadRequests();
  },
  methods: {
    loadRequests() {
      this.$store
        .dispatch("dukaGetRequest", "duka-root/get-requests")
        .then(response => {
          this.requests = response.data.requests;
        });
    },
    closeRequest(id) {
      if (confirm("Are you sure you want to confirm this withdraw request?")) {
        this.$store
          .dispatch(
            "dukaGetRequest",
            "duka-root/comfirm-withdraw-request/" + id
          )
          .then(() => {
            this.loadRequests();
          });
      }
    }
  }
};
</script>
