<template>
  <section>
    <duka-alert
      :modalTitle="alertDefaults.title"
      :modalContent="alertDefaults.content"
      :actionButton="alertDefaults.actionButton"
      @accept-alert="toggleActions(alertDefaults.actionType)"
      @close="closeAlert"
      :actionButtonClasses="alertDefaults.classes"
      v-if="alertDefaults.modalOpen"
    />

    <duka-modal-form
      v-if="modalOpen"
      @close="toggleModal"
      style="display:block"
      :modalSize="'default'"
      :headerColor="'#003E67'"
    >
      <span slot="head" style="padding-top:10px">Insert new category</span>
      <div slot="body">
        <div class="row justify-content-center my-2">
          <div class="col-lg-5">
            <label class="text-center">Category main Image/ icon</label>
            <h6>This is image that will be shown on landing page</h6>
            <div class="upload-box">
              <p class="text-center">
                <img :src="defaultCropImage" class="img img-fluid" />
              </p>
              <button
                type="button"
                class="btn btn-sm btn-outline-info"
                @click="toggleSingleUpload"
              >
                <span v-if="defaultCropImage">Edit main image</span>
                <span v-else>Upload Main image</span>
              </button>
              <my-upload
                field="duka_upload"
                @crop-success="dukaCropSuccess"
                @crop-upload-success="dukaUploadSuccess"
                v-model="showSingleUpload"
                :width="155"
                :height="137"
                :url="trashedImageUrl"
                lang-type="en"
                ref="singleUpload"
                img-format="png"
              />
            </div>
          </div>
          <div class="col-lg-7 my-2">
            <div class="form-group mb-1">
              <label for>Category name</label>
              <input
                type="text"
                placeholder="Category title or name"
                class="form-control"
                v-model="newCategory.category_name"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mt-1">
              <label for>
                Description(
                <em>Optional</em>)
              </label>
              <textarea
                class="form-control"
                rows="4"
                v-model="newCategory.desciption"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div slot="foot">
        <button
          type="button"
          class="btn btn-primary ml-auto"
          :disabled="!newCategory.category_name"
          @click="setCategory"
        >
          Save category
        </button>
      </div>
    </duka-modal-form>

    <div class="header bg-primary pb-6 ml--5 mr--5">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <h6 class="h2 text-white d-inline-block mb-0">Home</h6>
              <nav
                aria-label="breadcrumb"
                class="d-none d-md-inline-block ml-md-4"
              >
                <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0)">
                      <i class="fas fa-home"></i>
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0)">Products departments</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ category.category_name }}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">
                Products variations in {{ category.category_name }}
              </h3>
            </div>
            <div class="card-body">
              <h3>Variations</h3>
              <p>
                Add variant options like size and color to create variants for
                this product.
              </p>
              <div>
                <h3>Variations options</h3>
                <div class="text-center" v-if="!currentVariations.length">
                  <span class="text-mutted">No Option has been added yet.</span>
                  <br />
                  <button
                    class="btn btn-icon btn-outline-primary mt-2"
                    type="button"
                    @click="createVariation()"
                  >
                    <span class="btn-inner--icon">
                      <i class="ni ni-fat-add"></i>
                    </span>
                    <span class="btn-inner--text">Add variant options</span>
                  </button>
                </div>
                <div
                  class="row mt-4 mb-4 py-5"
                  style="border-bottom: 1px solid #dee2e6;"
                  v-for="(variation, indexx) in currentVariations"
                  :key="'variations' + indexx"
                >
                  <div class="col-sm-3">
                    <label for>Name</label>
                    <input
                      type="text"
                      placeholder="Eg: color, size, HDD, etc..."
                      class="form-control"
                      v-model="currentVariations[indexx].variation_name"
                    />
                    <a
                      class="text-danger"
                      href="javascript:void(0)"
                      @click.prevent="RemoveVariotion(indexx)"
                      >Delete option</a
                    >
                  </div>
                  <div class="col-sm-3">
                    <label for>Type</label>
                    <select
                      class="form-control"
                      v-model="currentVariations[indexx].variation_type"
                    >
                      <option value="null" disabled hidden>Select type</option>
                      <option value="radio">Radio Button</option>
                      <option value="dropdown">Drop down</option>
                      <option value="rectangular">Rectangular List</option>
                      <option value="images">Images</option>
                    </select>
                  </div>
                  <div class="col-sm-6">
                    <label for>Values</label>
                    <div
                      class="row mb-3"
                      v-for="(values, indexxx) in currentVariations[indexx]
                        .variation_values"
                      :key="'variation_values' + indexxx"
                    >
                      <div class="col-sm-7">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter value here"
                          v-model="
                            currentVariations[indexx].variation_values[indexxx]
                              .valuee
                          "
                        />
                      </div>
                      <div class="col-sm-3">
                        <div class="custom-control custom-radio mb-3">
                          <input
                            value="1"
                            v-model="
                              currentVariations[indexx].variation_values[
                                indexxx
                              ].isDefault
                            "
                            :name="'isdefault' + indexx"
                            class="custom-control-input"
                            :id="'customRadio6' + indexx + indexxx"
                            type="radio"
                          />
                          <label
                            class="custom-control-label"
                            :for="'customRadio6' + indexx + indexxx"
                            >Default</label
                          >
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <button
                          class="btn btn-sm btn-icon btn-outline-danger"
                          v-if="
                            currentVariations[indexx].variation_values.length >
                              1
                          "
                          type="button"
                          @click="RemoveVariationValuee(indexx, indexxx)"
                        >
                          <span class="btn-inner--icon">
                            <i class="ni ni-fat-remove"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                    <button
                      type="button"
                      @click="createVariationValuee(indexx)"
                      class="btn btn-secondary btn-sm mt-2"
                    >
                      <i class="ni ni-fat-add"></i> Add new value
                    </button>
                  </div>
                </div>
                <div v-if="currentVariations.length">
                  <button
                    class="btn btn-icon btn-outline-info mt-2"
                    type="button"
                    @click="createVariation()"
                  >
                    <span class="btn-inner--icon">
                      <i class="ni ni-fat-add"></i>
                    </span>
                    <span class="btn-inner--text">Add variant options</span>
                  </button>
                  <hr />
                  <button
                    class="btn btn-icon btn-outline-primary mt-2 float-right"
                    type="button"
                    @click="setVariation()"
                  >
                    <span class="btn-inner--icon">
                      <i class="ni ni-check-bold"></i>
                    </span>
                    <span class="btn-inner--text">Save changes</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProductsVariations",
  data: () => ({
    currentVariations: [],
    newVariations: {
      variations: null,
      category_id: null
    },
    category: {}
  }),
  created() {
    this.loadCategory();
  },
  methods: {
    loadCategory() {
      let getURL = "duka-root/get-single-category/" + this.$route.params.slug;
      this.$store.dispatch("dukaGetRequest", getURL).then(response => {
        this.category = response.data.category;
        this.currentVariations = response.data.variations;
      });
    },
    createVariation() {
      this.currentVariations.push({
        id: null,
        variation_name: null,
        variation_type: null,
        variation_values: [{ valuee: null, isDefault: 0 }]
      });
    },
    RemoveVariotion(key) {
      this.currentVariations.splice(key, 1);
    },
    createVariationValuee(key) {
      this.currentVariations[key].variation_values.push({
        valuee: null,
        isDefault: 0
      });
    },
    RemoveVariationValuee(indexa, key) {
      this.currentVariations[indexa].variation_values.splice(key, 1);
    },
    setVariation() {
      this.newVariations.variations = JSON.stringify(this.currentVariations);
      this.newVariations.category_id = this.category.id;
      let url = "duka-root/set-variations";
      var formData = this.formData(this.newVariations);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) this.loadCategory();
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
