<template>
  <div class="page-content-wrapper">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-sm-10">
          <div class="page-title-box">
            <div class="btn-group float-right">
              <ol class="breadcrumb hide-phone p-0 m-0">
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)">Boyoro admin</a>
                </li>
                <li class="breadcrumb-item active">Live chat</li>
              </ol>
            </div>
            <h4 class="page-title">Dashboard</h4>
          </div>
        </div>
      </div>

      <div class="email-wrapper wrapper mt-2">
        <div class="row pb-4 justify-content-center">
          <div
            class="mail-list-container col-md-3 pt-1 pb-4 border-right bg-white"
          >
            <template v-for="(chat, index) in chats">
              <div
                class="mail-list"
                @click="getChatMessages(chat)"
                :class="{ new_mail: choosenRow.master_id === chat.master_id }"
                :key="index"
              >
                <div class="content w-100">
                  <p class="sender-name w-100 mb-0">
                    {{ chat.chat_code }}

                    <span class="text-primary float-right">{{
                      chatDate(chat.message_date) +
                        " " +
                        chat.message_date.split(" ")[1]
                    }}</span>
                  </p>
                  <p class="message_text font-weight-bold">
                    {{ chat.message_body }}
                  </p>
                </div>
              </div>
            </template>
          </div>
          <div class="mail-view d-none d-md-block col-md-5 col-lg-7 bg-white">
            <div class="message-body">
              <div class="sender-details" v-if="!isEmpty(choosenRow)">
                <img
                  class="img-sm rounded-circle mr-3"
                  :src="dukaURL + 'assets/avatar.jpg'"
                />
                <div class="details">
                  <p class="msg-subject mb-0">{{ choosenRow.chat_code }}</p>
                  <p class="sender-email">{{ choosenRow.initialize_date }}</p>
                </div>
              </div>
              <div class="message-contents" v-if="messages.length">
                <div class="rest-container chat-rest-container h-100">
                  <div class="chat-container h-100" style="overflow-x:hidden">
                    <template v-for="(chat, i) in messages">
                      <div
                        class="left-align-message"
                        :key="'message' + i"
                        v-if="chat.message_sender === 'admin'"
                      >
                        <div class="message-info">
                          <div>
                            {{ chatDate(chat.message_date) }}
                          </div>
                          <div>
                            <span class="icon dripicons-clock"></span> 05:37 PM
                          </div>
                        </div>
                        <div
                          class="message-content"
                          v-html="chat.message_body"
                        ></div>
                        <div class="">
                          <span class="dripicons-user"></span>
                        </div>
                      </div>

                      <div
                        class="right-align-message"
                        :key="'message' + i"
                        v-else
                      >
                        <div class="message-info">
                          <div>
                            {{ chatDate(chat.message_date) }}
                          </div>
                          <div>
                            <span class="icon dripicons-clock"></span> 05:37 PM
                          </div>
                        </div>
                        <div
                          class="message-content"
                          v-html="chat.message_body"
                        ></div>
                        <div class="">
                          <span class="dripicons-user"></span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center w-100 border-top pt-2 mt-2"
                >
                  <div class="col-11 pl-0">
                    <textarea
                      v-model="newMessage.message"
                      class="form-control message-input rounded-0"
                      placeholder="Type your message"
                      rows="2"
                    ></textarea>
                  </div>
                  <div class="col-1 px-0">
                    <span
                      class="dripicons-direction send-chat blue-icon"
                      @click="sendMessage"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EmailApp",
  data: () => ({
    chats: [],
    messages: [],
    newMessage: {
      message: null,
      chat_id: null
    }
  }),
  created() {
    this.getChats();
  },
  methods: {
    getChats() {
      this.$store
        .dispatch("dukaGetRequest", "duka-root/get-chats")
        .then(response => {
          this.chats = response.data.chats;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getChatMessages(chat) {
      this.selectRecord(chat);
      this.$store.state.activeBtn = "fetchingmesssges";
      this.$store
        .dispatch(
          "dukaGetRequest",
          "duka-root/get-chat-messages/" + chat.master_id
        )
        .then(response => {
          this.messages = response.data.messages;
        })
        .catch(err => {
          console.log(err);
        });
    },
    sendMessage() {
      if (this.newMessage.message) {
        this.newMessage.chat_id = this.choosenRow.master_id;
        var formData = this.formData(this.newMessage);
        let url = "duka-root/send-chat";
        let DispatchpParams = { formData: formData, url: url };
        this.$store
          .dispatch("dukaPostRequest", DispatchpParams)
          .then(response => {
            if (!response.data.error) {
              this.clearObject(this.newMessage);
              this.messages = response.data.messages;
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }
};
</script>
<style scoped>
a,
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
  text-shadow: none;
}
.img-sm {
  width: 43px;
  height: 43px;
}
.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: calc(100vh - 59px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.content-wrapper {
  background: #fafafa;
  padding: 1.1rem 2.5rem 2.5rem;
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.align-items-stretch,
.email-wrapper .message-body .attachments-sections ul li,
.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
  align-items: stretch !important;
}
.email-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
  .bg-white {
    background-color: #ffffff !important;
  }

  .email-wrapper .mail-sidebar .menu-bar {
    width: 100%;
    float: right;
    height: 100%;
    min-height: 100%;
  }
}

.d-flex,
.loader-demo-box,
.layouts-preview-main-wrapper
  .layouts-preview-wrapper
  .preview-item
  a
  .item-title,
.list-wrapper ul li,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user,
.email-wrapper .mail-list-container .mail-list .details,
.email-wrapper .message-body .attachments-sections ul li .thumb,
.email-wrapper .message-body .attachments-sections ul li .details .buttons,
.lightGallery .image-tile .demo-gallery-poster,
.swal2-modal,
.sidebar .sidebar-profile,
.navbar .navbar-menu-wrapper .navbar-nav,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-item,
.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right .nav-item {
  display: flex !important;
}
.d-inline-flex,
.email-wrapper .message-body .attachments-sections ul li {
  display: inline-flex !important;
}

.flex-row,
.email-wrapper .message-body .attachments-sections ul li,
.navbar .navbar-menu-wrapper .navbar-nav {
  flex-direction: row !important;
}

.flex-column,
.nav-tabs.nav-tabs-vertical,
.nav-tabs.nav-tabs-vertical-custom,
.nav-pills.nav-pills-vertical,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user {
  flex-direction: column !important;
}
.justify-content-end,
.email-wrapper .mail-list-container .mail-list .details {
  justify-content: flex-end !important;
}
.justify-content-center,
.layouts-preview-main-wrapper
  .layouts-preview-wrapper
  .preview-item
  a
  .item-title,
.email-wrapper .message-body .attachments-sections ul li .thumb {
  justify-content: center !important;
}
.align-items-center,
.loader-demo-box,
.layouts-preview-main-wrapper
  .layouts-preview-wrapper
  .preview-item
  a
  .item-title,
.list-wrapper ul li,
.email-wrapper .message-body .attachments-sections ul li .thumb,
.email-wrapper .message-body .attachments-sections ul li .details .buttons,
.sidebar .sidebar-profile,
.navbar .navbar-menu-wrapper .navbar-nav,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-item,
.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right .nav-item {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch,
.email-wrapper .message-body .attachments-sections ul li,
.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
  align-items: stretch !important;
}

/*-------------------------------------------------------------------*/
/* === Email === */
/* Mail Sidebar */
@media (max-width: 769px) {
  .email-wrapper .mail-sidebar {
    position: relative;
  }
}

@media (max-width: 767.98px) {
  .email-wrapper .mail-sidebar {
    position: fixed;
    z-index: 99;
    background: #ffffff;
    width: 45%;
    min-width: 300px;
    left: -100%;
    display: block;
    transition: 0.4s ease;
    -webkit-transition: 0.4s ease;
    -moz-transition: 0.4s ease;
  }
}

.email-wrapper .mail-sidebar .menu-bar {
  width: 100%;
  float: right;
  height: 100%;
  min-height: 100%;
}

@media (max-width: 767.98px) {
  .email-wrapper .mail-sidebar .menu-bar {
    min-height: 100vh;
    max-height: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.email-wrapper .mail-sidebar .menu-bar .menu-items {
  padding: 0;
  margin-bottom: 0;
  height: auto;
  list-style-type: none;
}

.email-wrapper .mail-sidebar .menu-bar .menu-items li {
  padding: 10px 15px;
  transition: 0.4s;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.email-wrapper .mail-sidebar .menu-bar .menu-items li:hover {
  background: rgba(240, 244, 249, 0.8);
}

.email-wrapper .mail-sidebar .menu-bar .menu-items li a {
  color: #303a40;
  font-size: 0.875rem;
  text-decoration: none;
}

.email-wrapper .mail-sidebar .menu-bar .menu-items li a i {
  margin-right: 8px;
  font-size: 0.875rem;
  line-height: 1.5;
}

.email-wrapper .mail-sidebar .menu-bar .menu-items li.active {
  background: #e6e9ed;
  border-radius: 4px;
}

.email-wrapper .mail-sidebar .menu-bar .menu-items li.active a {
  color: #6201ed;
}

.email-wrapper .mail-sidebar .menu-bar .menu-items li.compose:hover {
  background: transparent;
}

.email-wrapper .mail-sidebar .menu-bar .online-status {
  margin-top: 1rem;
}

.email-wrapper .mail-sidebar .menu-bar .online-status .chat {
  font-size: 0.875rem;
  color: #6201ed;
  margin-bottom: 0;
  font-weight: 600;
}

.email-wrapper .mail-sidebar .menu-bar .online-status .status {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: flex-start;
  transform: translateX(-43px) translateY(2px);
}

.email-wrapper .mail-sidebar .menu-bar .online-status .status:after {
  font-size: 12px;
  color: #2e383e;
  margin: -5px 0 0 18px;
}

.email-wrapper .mail-sidebar .menu-bar .online-status .status.offline {
  background: #ff3366;
}

.email-wrapper .mail-sidebar .menu-bar .online-status .status.offline:after {
  content: "Offline";
}

.email-wrapper .mail-sidebar .menu-bar .online-status .status.online {
  background: #29c0b1;
}

.email-wrapper .mail-sidebar .menu-bar .online-status .status.online:after {
  content: "Online";
}

.email-wrapper .mail-sidebar .menu-bar .profile-list {
  padding: 10px 0;
}

.email-wrapper .mail-sidebar .menu-bar .profile-list-item {
  border-bottom: 1px solid #eee;
  padding: 6px 0;
  display: block;
}

.email-wrapper .mail-sidebar .menu-bar .profile-list-item:last-child {
  border-bottom: 0;
}

.email-wrapper .mail-sidebar .menu-bar .profile-list-item a {
  text-decoration: none;
}

.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .pro-pic {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  width: 20%;
  max-width: 40px;
}

.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .pro-pic img {
  max-width: 100%;
  width: 100%;
  border-radius: 100%;
}

.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user {
  width: 100%;
  padding: 5px 10px 0 15px;
}

.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user .u-name {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1;
  color: #000000;
}

.email-wrapper
  .mail-sidebar
  .menu-bar
  .profile-list-item
  a
  .user
  .u-designation {
  font-size: calc(0.875rem - 0.1rem);
  margin-bottom: 0;
}

.email-wrapper .sidebar.open {
  left: 0;
}

/* Mail List Container */
.email-wrapper .mail-list-container {
  border-left: 1px solid #eee;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.email-wrapper .mail-list-container a {
  text-decoration: none;
}

.email-wrapper .mail-list-container .mail-list {
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  width: 100%;
}

.email-wrapper .mail-list-container .mail-list:last-child {
  border-bottom: none;
}

.email-wrapper .mail-list-container .mail-list .form-check {
  margin-top: 12px;
  width: 11%;
  min-width: 20px;
}

.email-wrapper .mail-list-container .mail-list .content {
  width: 83%;
  padding-left: 0;
  padding-right: 0;
}

.email-wrapper .mail-list-container .mail-list .content .sender-name {
  font-size: 0.875rem;
  font-weight: 400;
  max-width: 95%;
}

.email-wrapper .mail-list-container .mail-list .content .message_text {
  margin: 0;
  max-width: 93%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.email-wrapper .mail-list-container .mail-list .details {
  width: 5.5%;
}

.email-wrapper .mail-list-container .mail-list .details .date {
  text-align: right;
  margin: auto 15px auto 0;
  white-space: nowrap;
}

.email-wrapper .mail-list-container .mail-list .details i {
  margin: auto 0;
  color: #ddd;
}

.email-wrapper .mail-list-container .mail-list .details i.favorite {
  color: #fbbc06;
}

.email-wrapper .mail-list-container .mail-list.new_mail {
  background: #e6e9ed;
}

.email-wrapper .mail-list-container .mail-list.new_mail .details .date {
  color: #000000;
}

/* Message Content */
.email-wrapper .message-body .sender-details {
  padding: 20px 15px 0;
  border-bottom: 1px solid #eee;
  display: -webkit-flex;
  display: flex;
}

.email-wrapper .message-body .sender-details .details {
  padding-bottom: 0;
}

.email-wrapper .message-body .sender-details .details .msg-subject {
  font-weight: 600;
}

.email-wrapper .message-body .sender-details .details .sender-email {
  margin-bottom: 20px;
  font-weight: 400;
}

.email-wrapper .message-body .sender-details .details .sender-email i {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 1px 0 7px;
}

.email-wrapper .message-body .message-contents {
  padding: 50px 15px;
  height: 70vh;
}

.email-wrapper .message-body .attachments-sections ul {
  list-style: none;
  border-top: 1px solid #eee;
  padding: 30px 15px 20px;
}

.email-wrapper .message-body .attachments-sections ul li {
  padding: 10px;
  margin-right: 20px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.email-wrapper .message-body .attachments-sections ul li .thumb {
  display: inline-block;
  margin-right: 10px;
}

.email-wrapper .message-body .attachments-sections ul li .thumb i {
  font-size: 30px;
  margin: 0;
  color: #2e383e;
}

.email-wrapper .message-body .attachments-sections ul li .details p.file-name {
  display: block;
  margin-bottom: 0;
  color: #2e383e;
}

.email-wrapper
  .message-body
  .attachments-sections
  ul
  li
  .details
  .buttons
  .file-size {
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 13px;
}

.email-wrapper .message-body .attachments-sections ul li .details .buttons a {
  font-size: 13px;
  margin-right: 10px;
}

.email-wrapper
  .message-body
  .attachments-sections
  ul
  li
  .details
  .buttons
  a:last-child {
  margin-right: 0;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
.form-check .form-check-label input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.form-check {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
}
.email-wrapper .mail-list-container .mail-list .form-check {
  margin-top: 12px;
  width: 11%;
  min-width: 20px;
}
.mail-list {
  cursor: pointer !important;
}
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 4px 12px 0px 12px;
  height: calc(100% - 6px);
  overflow: scroll;
}
.chat-container .calendar-icon {
  height: 13px;
  margin-top: -3px;
}
.chat-container .left-align-message,
.chat-container .right-align-message {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 16px 0px 32px 0px;
}
.chat-container .left-align-message .message-info,
.chat-container .right-align-message .message-info {
  flex-flow: column;
  display: flex;
  font-weight: 300;
  color: #aaaaaa;
  font-family: "Roboto Condensed";
  font-size: 11px;
  text-align: right;
  min-width: 70px;
}
.chat-container .left-align-message .message-info .icon,
.chat-container .right-align-message .message-info .icon {
  margin-right: 8px;
}
.chat-container .left-align-message .message-info .icon img,
.chat-container .right-align-message .message-info .icon img {
  height: 10px;
}
.chat-container .left-align-message .message-info .fas,
.chat-container .right-align-message .message-info .fas {
  font-weight: 300;
  margin-right: 10px;
  font-size: 10px;
}
.chat-container .left-align-message .message-content,
.chat-container .right-align-message .message-content {
  padding: 8px 24px;
  border-radius: 6px;
  margin: 0 8px;
  background: #efeff4;
  font-weight: 300;
  font-size: 13px;
  font-family: "SF Pro Regular";
}
.chat-container .left-align-message .message-content.dots,
.chat-container .right-align-message .message-content.dots {
  letter-spacing: 6px;
  font-weight: 900;
}
.chat-container .left-align-message .profile-picture,
.chat-container .right-align-message .profile-picture {
  margin-bottom: -16px;
  min-width: 45px;
}

.chat-container .left-align-message .profile-picture .status,
.chat-container .right-align-message .profile-picture .status {
  vertical-align: bottom;
  color: #00a457;
  margin-right: -3px;
  font-size: 9px;
}
.chat-container .left-align-message .profile-picture .profile-picture-image,
.chat-container .right-align-message .profile-picture .profile-picture-image {
  height: 32px;
}
.chat-container .left-align-message .message-content {
  border-bottom-right-radius: 0px;
}
.chat-container .left-align-message .message-content.dots {
  letter-spacing: 6px;
  font-weight: 900;
}
.chat-container .right-align-message {
  flex-flow: row-reverse;
}
.chat-container .right-align-message .message-content {
  border-bottom-left-radius: 0px;
  background-color: #041e42;
  color: #fff;
}
.chat-container .right-align-message .message-content.dots {
  letter-spacing: 6px;
  font-weight: 900;
}
.chat-container .right-align-message .message-info {
  text-align: left;
}
.send-message {
  position: absolute;
  bottom: 70px;
  width: 100%;
}
.send-message .send-message-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  width: 100%;
  border-top: 1px solid #f1f3f8;
  color: #c7c7c7;
}
.send-message .send-message-container .message-input {
  height: 40px;
  line-height: 40px;
  width: 60%;
  border: 1px solid #f1f3f8;
  outline: none;
  padding: 0 10px;
  font-weight: 300;
}
.chat-container .profile-picture .profile-picture-image {
  height: auto;
  width: 36px;
}
</style>
