<template>
  <section class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mx-auto">
          <div class="card">
            <div class="card-body border-bottom">
              <h4 class="header-title">
                List of registered customers
                <span class="badge badge-pill rounded-circle badge-primary">{{
                  customers.length
                }}</span>
              </h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table mb-0 table-centered">
                  <thead>
                    <tr>
                      <th>Customer name</th>
                      <th>Phone number</th>
                      <th>Email address</th>
                      <th>Country</th>
                      <th>Address</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(customer, index) in customers"
                      :key="'customer' + index"
                    >
                      <td>
                        <img
                          :src="dukaURL + 'assets/avatar.jpg'"
                          class="rounded-circle thumb-sm mr-1"
                        />
                        {{ customer.firstname }} {{ customer.lastname }}
                      </td>
                      <td>{{ customer.phone }}</td>
                      <td>{{ customer.email }}</td>
                      <td>{{ customer.country }}</td>
                      <td>{{ customer.address }}</td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-sm btn-danger ml-2"
                        >
                          <i class="ti-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Customers",
  data: () => ({
    customers: []
  }),
  created() {
    this.loadCustomers();
  },
  methods: {
    loadCustomers() {
      this.$store
        .dispatch("dukaGetRequest", "duka-root/get-customers")
        .then(response => {
          console.log(response.data);
          this.customers = response.data.customers;
        });
    }
  }
};
</script>
