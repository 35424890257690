<template>
  <section class="py-4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-8">
          <div class="page-title-box">
            <div class="btn-group float-right">
              <ol class="breadcrumb hide-phone p-0 m-0">
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)">Boyoro admin</a>
                </li>
                <li class="breadcrumb-item active">Companies Balances</li>
              </ol>
            </div>
            <h4 class="page-title">Dashboard</h4>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card">
            <div class="card-body border-botttom">
              <h4 class="header-title">
                Companies Accoounts
              </h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table mb-0 table-centered">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Company</th>
                      <th>Offers total</th>
                      <th>Withdrawed total</th>
                      <th>Current Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(company, index) in companies"
                      :key="'company' + index"
                    >
                      <td>{{ incrementIndex(index) }}</td>
                      <td>
                        {{ company.company_name }}
                      </td>
                      <td>USD {{ company.total || 0 }}</td>
                      <td>USD {{ company.withdraw || 0 }}</td>
                      <td>USD {{ company.balance || 0 }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Companies",
  data: () => ({
    companies: []
  }),
  created() {
    this.loadBalances();
  },
  methods: {
    loadBalances() {
      this.$store
        .dispatch("dukaGetRequest", "duka-root/get-companies-balances")
        .then(response => {
          this.companies = response.data.companies;
        });
    },
    setCompany() {
      let url = "duka-root/create-company";
      var formData = this.formData(this.newCompany);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.clearObject(this.newCompany);
            this.loadCompanies();
            this.toggleModal();
          }
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
