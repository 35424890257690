<template>
  <section>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <div class="card">
            <div class="card-body">
              <h4 class="mb-0">
                Select category of the product you are adding
              </h4>
              <hr />
              <nav
                aria-label="breadcrumb"
                class="d-none d-md-inline-block ml-md-4"
              >
                <ol class="breadcrumb breadcrumb-links breadcrumb-primary mb-0">
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0)">
                      <i class="ti-list"></i>
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0)">Products</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0)">Categories</a>
                  </li>
                  <li
                    class="breadcrumb-item"
                    :class="{
                      active: isEqual(
                        breadcrumbItems.length,
                        breadcrumbItems.indexOf(item)
                      )
                    }"
                    v-for="item of breadcrumbItems"
                    :key="'breadcrumbItems-' + item + uuidv4()"
                  >
                    <a href="javascript:void(0)">{{ item }}</a>
                  </li>
                </ol>
              </nav>
              <div class="row">
                <div
                  class="col-sm-4 mb-3"
                  v-for="level of levels"
                  :key="'category' + level"
                >
                  <duka-categories
                    @showparent="getNewParent"
                    :key="'category' + level"
                    :parentId="level"
                    :childLevel="levels.indexOf(level) + 1"
                    :currentSlug="currentCategorySlug"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SellerProductCategory from "../../components/SellerProductCategory";
export default {
  name: "ProductCategories",
  components: {
    "duka-categories": SellerProductCategory
  },
  data() {
    return {
      levels: [],
      breadcrumbItems: [],
      currentCategorySlug: null
    };
  },
  created() {
    // Create level one category
    this.addLevel(0);
  },
  methods: {
    addLevel(id) {
      this.levels.push(id);
    },
    RemoveLevel(key) {
      this.levels.splice(key, 1);
    },
    getNewParent(eventObj) {
      this.levels = this.levels.slice(0, Number(eventObj.level));
      this.currentCategorySlug = eventObj.slug;
      if (Number(eventObj.level) === 1) {
        this.breadcrumbItems = [];
      } else {
        this.breadcrumbItems = this.breadcrumbItems.slice(
          0,
          Number(eventObj.level)
        );
      }
      this.breadcrumbItems.push(eventObj.categoryName);
      this.addLevel(Number(eventObj.id));
    }
  }
};
</script>
