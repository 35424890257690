<template>
  <div>
    <wolf-drawer
      v-if="modalOpen"
      @close="toggleModal"
      style="display:block"
      :modalSize="'default'"
      :headerColor="'#000'"
    >
      <span slot="head" style="padding-top:10px">Add products widget</span>
      <div slot="body">
        <div class="row align-items-center mt-1">
          <div class="col-lg-5">
            <div v-if="defaultCropImage">
              <div
                class="jb-gallery-upload text-center py-1"
                style="height:auto!important"
              >
                <img
                  :src="defaultCropImage"
                  class="img img-thumbails rounded img-fluid"
                />
                <a
                  class="text-danger mt-2 w-100"
                  href="javascript:void(0)"
                  @click.prevent="handleFileReset"
                  >Delete image</a
                >
              </div>
            </div>

            <div class="jb-gallery-upload" v-else @click="toggleSingleUpload">
              <p class="text-center">
                <img
                  :src="apiURL + 'assets/upload-icon.png'"
                  class="img img-fluid"
                />
              </p>
              <p class="text-mutted text-center">
                Click to add main image here to upload
              </p>
            </div>

            <my-upload
              field="duka_upload"
              @crop-success="dukaCropSuccess"
              @crop-upload-success="dukaUploadSuccess"
              v-model="showSingleUpload"
              :width="274"
              :height="225"
              :url="trashedImageUrl"
              lang-type="en"
              ref="singleUpload"
              img-format="png"
            />
          </div>
          <div class="col-lg-7 my-2">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                v-model="newWidget.category_title"
                placeholder="Widget title"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                v-model="newWidget.min_price"
                placeholder="Min Price"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                v-model="newWidget.url_string"
                placeholder="URL Link"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                v-model="newWidget.button_caption"
                placeholder="Button caption"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h6>Widget Gallery ( 2 images recommended)</h6>

            <my-upload
              field="duka_upload"
              @crop-success="dukaGalleryImage"
              @crop-upload-success="dukaGalleryUpload"
              v-model="showGalleryUpload"
              :width="175"
              :height="164"
              :url="trashedImageUrl"
              lang-type="en"
              ref="galleryUpload"
              img-format="png"
            />

            <template v-if="dukaGallery.length">
              <div class="row border-bottom py-2 align-items-center">
                <div
                  class="col-lg-2 mb-1 text-center"
                  v-for="(tempImg, key) in dukaGallery"
                  :key="'gallery' + key"
                >
                  <img
                    :src="tempImg.image"
                    class="img img-thumbnail rounded img-fluid"
                  />
                  <a
                    href="#"
                    @click.prevent="RemoveGalleryImage(key)"
                    class="w-100 text-danger"
                  >
                    Remove
                  </a>
                </div>
              </div>
              <div
                class="jb-gallery-upload mt-2"
                style="height:30px;"
                @click="toggleGalleryUpload"
              >
                <p class="text-center mb-0">
                  <img
                    :src="apiURL + 'assets/upload-icon.png'"
                    class="img img-fluid"
                    style="width: 65%;"
                  />
                </p>
                <p class="text-mutted mb-0">
                  Add more images into widget's gallery
                </p>
              </div>
            </template>
            <div class="jb-gallery-upload" v-else @click="toggleGalleryUpload">
              <p class="text-center mb-0">
                <img
                  :src="apiURL + 'assets/upload-icon.png'"
                  class="img img-fluid"
                />
              </p>
              <p class="text-mutted">
                No images available in the widget gallery now. <br />
                Click to add images into widget's gallery
              </p>
            </div>
          </div>
        </div>
      </div>
      <div slot="foot">
        <button
          type="button"
          class="btn btn-primary ml-auto"
          :disabled="!newWidget.category_title"
          @click="setWidget"
        >
          <span v-if="newWidget.id">Edit widget</span>
          <span v-else>Save widget</span>
        </button>
      </div>
    </wolf-drawer>
    <div class="row justify-content-center mt-3">
      <div class="col-lg-11">
        <div class="card">
          <div class="card-body border-bottom">
            <h4 class="w-100">
              Products widget
              <button
                type="button"
                class="btn btn-sm btn-outline-primary float-right"
                @click="toggleModal"
              >
                Add new widget
              </button>
            </h4>
          </div>
          <div class="card-body">
            <div class="table-responsive table-responsive-sm">
              <table class="table table-sm align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Widget Image</th>
                    <th>Widget title</th>
                    <th>Min Price</th>
                    <th>URL</th>
                    <th>Button label</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(widget, index) in widgets"
                    :key="'widget' + index"
                  >
                    <td>
                      <img
                        :src="apiURL + 'uploads/widgets/' + widget.main_image"
                        class="rounded"
                        v-if="widget.main_image"
                        style="width: 120px;"
                      />
                      <img
                        :src="apiURL + 'assets/upload-icon.png'"
                        class="rounded img-thumbnail"
                        v-else
                      />
                    </td>
                    <td>{{ widget.category_title }}</td>
                    <td>RWF {{ addComma(widget.min_price) }}</td>
                    <td>{{ widget.url_string }}</td>
                    <td>{{ widget.button_caption }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-primary mr-2"
                        title="Edit widget"
                      >
                        <i class="ti-pencil"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger ml-2"
                        title="Delete widget"
                      >
                        <i class="ti-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myUpload from "vue-image-crop-upload";
export default {
  name: "ProductsWidget",
  components: {
    "my-upload": myUpload
  },
  data: () => ({
    widgets: [],
    newWidget: {
      category_title: null,
      main_image: null,
      gallery: [],
      min_price: null,
      url_string: null,
      button_caption: null
    }
  }),
  created() {
    this.loadWidgets();
    this.trashedImageUrl = this.trashedImageUrl + "/widgets";
  },
  methods: {
    loadWidgets() {
      let getURL = "duka-root/get-widgets";
      this.$store.dispatch("dukaGetRequest", getURL).then(response => {
        this.widgets = response.data.widgets;
        this.clearObject(this.newWidget);
      });
    },
    setWidget() {
      this.newWidget.main_image = this.activeCroppedImage;
      this.newWidget.gallery = JSON.stringify(this.defaultCropGallery);
      let url = "duka-root/set-widget";
      var formData = this.formData(this.newWidget);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.handleFileReset();
            this.loadWidgets();
            this.toggleModal();
            this.defaultCropGallery = [];
          }
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
