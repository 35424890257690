<template>
  <section class="pt-4">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Create and manage product categories</h3>
            </div>
            <div class="card-body">
              <nav
                aria-label="breadcrumb"
                class="d-none d-md-inline-block ml-md-4 mb-2"
              >
                <ol class="breadcrumb breadcrumb-links breadcrumb-primary">
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0)">
                      <i class="ti-list"></i>
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0)">Products</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="javascript:void(0)">Categories</a>
                  </li>
                  <li
                    class="breadcrumb-item"
                    :class="{
                      active: isEqual(
                        breadcrumbItems.length,
                        breadcrumbItems.indexOf(item)
                      )
                    }"
                    v-for="item of breadcrumbItems"
                    :key="'breadcrumbItems-' + item + uuidv4()"
                  >
                    <a href="javascript:void(0)">{{ item }}</a>
                    <router-link
                      :to="{
                        name: 'Productsvariations',
                        params: { slug: currentCategorySlug }
                      }"
                      v-if="
                        breadcrumbItems.length > 1 &&
                          isEqual(
                            breadcrumbItems.length,
                            breadcrumbItems.indexOf(item)
                          )
                      "
                      class="btn btn-primary btn-sm ml-3"
                      >variations</router-link
                    >
                  </li>
                </ol>
              </nav>
              <div class="row">
                <div
                  class="col-sm-4 mt-2"
                  v-for="level of levels"
                  :key="'category' + level"
                >
                  <duka-categories
                    @showparent="getNewParent"
                    :key="'category' + level"
                    :parentId="level"
                    :childLevel="levels.indexOf(level) + 1"
                    :pathCode="currentPathCode"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DukaProductCategory from "../../components/DukaProductCategory";
export default {
  name: "ProductCategories",
  components: {
    "duka-categories": DukaProductCategory
  },
  data() {
    return {
      levels: [],
      breadcrumbItems: [],
      currentCategorySlug: null,
      currentPathCode: null
    };
  },
  created() {
    // Create level one category
    this.addLevel(0);
  },
  methods: {
    addLevel(id) {
      this.levels.push(id);
    },
    RemoveLevel(key) {
      this.levels.splice(key, 1);
    },
    getNewParent(eventObj) {
      this.levels = this.levels.slice(0, Number(eventObj.level));
      this.currentCategorySlug = eventObj.slug;
      this.currentPathCode = eventObj.pathCode;
      if (Number(eventObj.level) === 1) {
        this.breadcrumbItems = [];
      } else {
        this.breadcrumbItems = this.breadcrumbItems.slice(
          0,
          Number(eventObj.level)
        );
      }
      this.breadcrumbItems.push(eventObj.categoryName);
      this.addLevel(Number(eventObj.id));
    }
  }
};
</script>
