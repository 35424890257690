<template>
  <div>
    <div class="ant-transfer-list">
      <div class="ant-transfer-list-header">
        <label class="ant-checkbox-wrapper">
          <span class="ant-checkbox">
            <input type="checkbox" class="ant-checkbox-input" value />
            <span class="ant-checkbox-inner"></span>
          </span>
        </label>
        <span class="ant-transfer-list-header-selected">
          <span>{{ filteredCategories.length }} categories</span>
          <span class="ant-transfer-list-header-title"></span>
        </span>
      </div>
      <div class="ant-transfer-list-body ant-transfer-list-body-with-search">
        <div class="ant-transfer-list-body-search-wrapper">
          <div>
            <input
              placeholder="Search here"
              type="text"
              v-model="searchKey"
              class="ant-input ant-transfer-list-search"
            />
            <span class="ant-transfer-list-search-action">
              <i class="anticon anticon-search">
                <svg
                  viewBox="64 64 896 896"
                  data-icon="search"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class
                >
                  <path
                    d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"
                  />
                </svg>
              </i>
            </span>
          </div>
        </div>
        <div class="ant-transfer-list-content">
          <ul
            v-if="categories.length"
            class="list-group list-group-flush list my--1"
          >
            <li
              class="list-group-item pl-2"
              v-for="(category, index) in filteredCategories"
              :key="'categoryTable' + index"
              @click="showCurrentParentId(category)"
            >
              <div class="row align-items-center">
                <div class="col-auto pr-1">
                  <!-- Avatar -->
                  <a href="javascript:void(0)">
                    <img
                      :alt="category.category_name"
                      :src="dukaURL + 'uploads/categories/' + category.image"
                      v-if="category.image"
                      class="img img-thumbnail img-fluid rounded"
                      style="width: 38px;"
                    />
                    <img
                      :alt="category.category_name"
                      :src="dukaURL + 'assets/upload-icon.png'"
                      class="img img-thumbnail img-fluid rounded"
                      style="width: 38px;"
                      v-else
                    />
                  </a>
                </div>
                <div class="col-9 pl-1">
                  <span class="mb-0">
                    <a href="javascript:void(0)">{{
                      category.category_name
                    }}</a>
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <div class="d-flex m-auto pt-5" v-else>
            <router-link
              :to="{ name: 'createproduct', params: { slug: currentSlug } }"
              class="btn btn-primary m-auto"
            >
              Select and continue
              <i class="ti-arrow-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SellerProductCategory",
  props: {
    parentId: {
      type: Number,
      required: true
    },
    childLevel: {
      type: Number,
      required: true
    },
    currentSlug: null,
    searchKey: null
  },
  data: () => ({
    categories: []
  }),
  computed: {
    filteredCategories: function() {
      let searchKey = this.searchKey && this.searchKey.toLowerCase();
      let categories = this.categories;
      if (searchKey) {
        categories = categories.filter(row => {
          return Object.keys(row).some(key => {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(searchKey) > -1
            );
          });
        });
      }
      return categories;
    }
  },
  created() {
    this.loadCategories();
  },
  methods: {
    showCurrentParentId(category) {
      let newLevel = {
        id: category.id,
        level: this.childLevel,
        categoryName: category.category_name,
        slug: category.category_slug
      };
      this.$emit("showparent", newLevel);
    },
    loadCategories() {
      let getURL = "duka-root/load-categories/" + this.parentId;
      this.$store.dispatch("dukaGetRequest", getURL).then(response => {
        this.categories = response.data.categories;
      });
    }
  }
};
</script>
<style scoped>
body::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 0.6em;
}

body::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #134070;
  border-radius: 1px;
}
.ant-transfer-list {
  border: 1px solid #d9d9d9;
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
  position: relative;
  width: 100%;
  height: 350px;
  padding-top: 34px;
}
.ant-transfer {
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
}
.ant-transfer-list-header {
  padding: 12px;
  border-radius: 4px 4px 0 0;
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e8e8e8;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ant-checkbox-wrapper {
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: unset;
  cursor: pointer;
  display: inline-block;
}
.ant-checkbox {
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  top: -0.09em;
}
.ant-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
  border-collapse: separate;
}
.ant-checkbox-inner:after {
  transform: rotate(45deg) scale(0);
  position: absolute;
  left: 4.57142857px;
  top: 1.14285714px;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  opacity: 0;
}
.ant-checkbox-wrapper + span,
.ant-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-transfer-list-header-title {
  position: absolute;
  right: 2px;
  bottom: -5px;
}
.ant-transfer-list-body-with-search {
  padding-top: 52px;
}
.ant-transfer-list-body {
  font-size: 14px;
  position: relative;
  height: 100%;
}
.ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 10px;
  left: 0;
  padding: 4px;
  width: 100%;
}
.ant-transfer-list-search {
  padding: 0 8px;
}
.ant-input {
  font-variant: tabular-nums;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 38px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-search-action {
  color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  width: 28px;
  line-height: 32px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.25);
}
.anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.anticon svg {
  display: inline-block;
}
svg:not(:root) {
  overflow: hidden;
}

.anticon > * {
  line-height: 1;
}
.ant-transfer-list-content {
  height: 100%;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}
.list-group-item {
  padding: 0.3rem 1rem;
  cursor: pointer;
  transition: all 0.3s;
}
.list-group-item:hover {
  background-color: #f5f5f5;
}
.btn-icon-only.btn-sm {
  width: 2rem;
  height: 2rem;
  line-height: 15px;
  padding-left: 8px;
  font-size: 16px;
}
.upload-container {
  border: 4px dashed #b4b9be;
  height: 200px;
}
</style>
