<template>
  <section>
    <duka-modal
      v-if="modalOpen && viewMode.addressMode"
      @close="toggleModal"
      style="display:block"
    >
      <span slot="head" style="padding-top:10px">
        Order #{{ choosenRow.order_code }}
        <span class="text-white float-right">Shipping address</span>
      </span>
      <div slot="body">
        <h4>Order Shipping address</h4>
        <div class="border p-3">
          <h6>
            Ship to:
            <span class="float-right font-weight-bold">{{
              choosenRow.address.firstname + " " + choosenRow.address.lastname
            }}</span>
          </h6>
          <h6>
            Email address:
            <span class="float-right font-weight-bold">{{
              choosenRow.address.email
            }}</span>
          </h6>
          <h6>
            Phone number:
            <span class="float-right font-weight-bold">{{
              choosenRow.address.phone
            }}</span>
          </h6>
          <h6>
            Country:
            <span class="float-right font-weight-bold">
              {{ choosenRow.address.country }}</span
            >
          </h6>
          <h6>
            City:
            <span class="float-right font-weight-bold">{{
              choosenRow.address.city
            }}</span>
          </h6>
          <h6>
            Zip code:
            <span class="float-right font-weight-bold">{{
              choosenRow.address.zip_code
            }}</span>
          </h6>
          <h6>
            Address line one:
            <span class="float-right font-weight-bold">{{
              choosenRow.address.address_line_one
            }}</span>
          </h6>
          <h6>
            Address line two:
            <span class="float-right font-weight-bold">{{
              choosenRow.address.address_line_two
            }}</span>
          </h6>
        </div>
      </div>
    </duka-modal>

    <duka-modal
      v-if="modalOpen && viewMode.detailsMode"
      @close="toggleModal"
      style="display:block"
      :modalSize="'modal-xg'"
    >
      <span slot="head" style="padding-top:10px">
        Order #{{ choosenRow.order_number }}
        <span class="text-white float-right">{{ choosenRow.status }}</span>
      </span>
      <div slot="body">
        <div class="table-responsive table-responsive-sm">
          <table class="table table-sm align-items-center mb-0 table-centered">
            <thead class="thead-light">
              <tr>
                <th>Product</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(detail, index) in choosenRow.details"
                :key="'detail' + index"
              >
                <td class="table-user" style="display:flex">
                  <div class="media align-items-center">
                    <img
                      :src="dukaURL + 'uploads/' + detail.product.image"
                      :alt="detail.product.product_title"
                      class="avatar rounded mr-3"
                      v-if="detail.product.image"
                    />
                    <img
                      :src="dukaURL + 'dukamaket-content/avatar.png'"
                      :alt="detail.product.product_title"
                      class="avatar rounded mr-3"
                      v-else
                    />
                    <div class="media-body">
                      <b>{{ detail.product.product_title }}</b>
                      <br />
                      <span
                        v-for="(option, i) in detail.options"
                        :key="i"
                        class="text-danger"
                        >{{ i }} : {{ detail.options[i] }},</span
                      >
                    </div>
                  </div>
                </td>
                <td>
                  <span class="text-muted">{{ detail.quantity_ordered }}</span>
                </td>

                <td>RWF {{ addComma(detail.price_each) }}</td>
                <td>RWF {{ addComma(detail.total_amount) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div slot="foot"></div>
    </duka-modal>

    <duka-modal
      v-if="modalOpen && viewMode.editMode"
      @close="toggleModal"
      style="display:block"
    >
      <span slot="head" style="padding-top:10px">
        Edit Order #{{ choosenRow.order_number }}
        <span class="text-white float-right">{{ choosenRow.status }}</span>
      </span>
      <div slot="body">
        <div class="form-group">
          <label>Current Status</label>
          <select v-model="choosenRow.status" class="form-control">
            <option :value="status" v-for="status in statuses" :key="status">{{
              status
            }}</option>
          </select>
        </div>
        <div class="form-group mb-0">
          <label>Your comment</label>
          <textarea
            v-model="newEdit.comment"
            class="form-control"
            rows="3"
            style="height:auto!important"
            placeholder="Type your comment"
          ></textarea>
        </div>
      </div>
      <div slot="foot">
        <button
          type="button"
          class="btn btn-primary ml-auto"
          @click="editCurrentOrder()"
        >
          Save changes
        </button>
      </div>
    </duka-modal>

    <div class="container-fluid">
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body border-bottom">
              <h4 class="mt-0 header-title">List of Orders</h4>
              <p class="text-muted font-13">
                List of submitted orders including pending and competed orders
              </p>
            </div>
            <div class="card-body">
              <div class="pb-2">
                <div
                  class="d-flex flex-wrap justify-content-center justify-content-sm-between pb-2"
                >
                  <div class="d-flex flex-wrap align-items-center">
                    <div class="form-inline flex-nowrap mr-3 pb-3">
                      <label class="mr-2 d-none d-sm-block" for="number"
                        >Show:</label
                      >
                      <select
                        class="form-control form-control-sm custom-select mr-sm-2"
                        style="padding-right:28px!important"
                        id="number"
                        @change="changePaginationRows($event)"
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="40">40</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                        <option value="120">120</option>
                        <option value="200">200</option>
                        <option value="10000">All</option>
                      </select>
                      <label class="ml-2 d-none d-sm-block"
                        >From : {{ recordsLength }} Orders</label
                      >
                    </div>
                  </div>
                  <div class="form-inline flex-nowrap mr-3 mr-sm-4 pb-3">
                    <input
                      type="search"
                      v-model="filterKey"
                      placeholder="Search for an order"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="table-responsive table-responsive-sm">
                <table class="table table-sm align-items-center table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th>Customer name</th>
                      <th>Order date</th>
                      <th>Order Code</th>
                      <th>Order amount</th>
                      <th>Status</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(order, index) in filteredRecords"
                      :key="'order' + index"
                    >
                      <td>
                        <i class="dripicons-user"></i> {{ order.firstname }}
                        {{ order.lastname }}
                      </td>
                      <td>
                        <span class="text-muted">
                          {{ formatOrderDate(order.order_date) }}
                        </span>
                      </td>
                      <td>#{{ order.order_code }}</td>
                      <td>RWF {{ addComma(order.order_amount) }}</td>
                      <td>
                        <span
                          class="text-primary"
                          style="text-decoration:underline"
                          >{{ order.status }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="badge badge-boxed badge-soft-warning"
                          v-if="!Number(order.order_paid)"
                          >Pending</span
                        >
                        <span
                          class="badge badge-boxed badge-soft-success"
                          v-else
                          >Paid</span
                        >
                      </td>
                      <td class="text-right">
                        <div class="dropdown">
                          <a
                            class="btn btn-sm btn-icon-only text-light"
                            href="javascript:void(0)"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              class="dripicons-view-list font-20 text-muted"
                            ></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          >
                            <a
                              href="javascript:void(0)"
                              class="dropdown-item"
                              @click.prevent="loadOrderAddress(order)"
                            >
                              <i class="dripicons-location"></i> shipping
                              address
                            </a>
                            <a
                              href="javascript:void(0)"
                              class="dropdown-item"
                              @click.prevent="
                                selectRecord(order);
                                openDetails();
                              "
                            >
                              <i class="dripicons-cart"></i> Ordered products
                            </a>
                            <a
                              class="dropdown-item"
                              href="javascript:void(0)"
                              @click.prevent="
                                selectRecord(order);
                                openEdit();
                              "
                            >
                              <i class="dripicons-pencil"></i>Edit status</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="card-footer py-4">
              <pagination
                :current_page="currentPage"
                :row_count_page="rowCountPage"
                @page-update="pageUpdate"
                :total_records="totalRows"
                :page_range="pageRange"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MyPaginations from "@/components/Paginations.vue";
export default {
  name: "Orders",
  components: {
    pagination: MyPaginations
  },
  data() {
    return {
      orders: [],
      viewMode: {
        detailsMode: false,
        invoiceMode: false,
        addressMode: false,
        editMode: false
      },
      newEdit: {
        comment: null
      },
      statuses: [
        "Pending",
        "Awaiting Payment",
        "Awaiting Fulfillment",
        "Awaiting Shipment",
        "Awaiting Pickup",
        "Partially Shipped",
        "Completed",
        "Shipped",
        "Cancelled",
        "Declined",
        "Refunded",
        "Disputed"
      ]
    };
  },

  created() {
    this.responseRef = "orders";
    this.paginationURL = "duka-root/get-orders";
    this.loadPaginationRecords();
  },
  methods: {
    openDetails() {
      this.toggleModal();
      this.falseObject(this.viewMode, "detailsMode");
    },
    openEdit() {
      this.toggleModal();
      this.falseObject(this.viewMode, "editMode");
    },
    loadOrders() {
      this.$store
        .dispatch("dukaGetRequest", "duka-root/get-orders")
        .then(response => {
          this.orders = response.data.orders;
        });
    },
    loadOrderAddress(order) {
      this.selectRecord(order);
      if (this.choosenRow.address) {
        this.toggleModal();
        this.falseObject(this.viewMode, "addressMode");
      } else {
        this.$store
          .dispatch(
            "dukaGetRequest",
            "duka-root/get-order-shipping-address/" + this.choosenRow.ship_to
          )
          .then(response => {
            this.choosenRow.address = response.data.address;
            this.toggleModal();
            this.falseObject(this.viewMode, "addressMode");
          });
      }
    },
    editCurrentOrder() {
      let url = "duka-root/edit-order";
      this.newEdit.order_code = this.choosenRow.order_code;
      this.newEdit.status = this.choosenRow.status;
      this.newEdit.order_number = this.choosenRow.order_number;
      var formData = this.formData(this.newEdit);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.toggleModal();
            this.loadOrders();
          }
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
<style scoped>
.customer-info,
.customer-avatar {
  display: inline-table !important;
}
.avatar {
  font-size: 1rem;
  display: inline-flex;
  width: 48px;
  height: 48px;
  color: #fff;
  border-radius: 0.375rem;
  background-color: #adb5bd;
  align-items: center;
  justify-content: center;
}
</style>
