<template>
  <section class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mx-auto">
          <div class="card">
            <div class="card-body border-bottom">
              <h4 class="header-title">
                Offers Payments Tracking
                <span class="badge badge-primary badge-pill rounded-circle">{{
                  dbResponse.payments.length
                }}</span>
              </h4>
            </div>
            <div class="card-body">
              <div
                class="d-flex flex-wrap justify-content-center justify-content-sm-between pb-3"
              >
                <div class="d-flex flex-wrap">
                  <div class="form-inline flex-nowrap pb-3">
                    <label class="mr-2 d-none d-sm-block" for="number"
                      >Show:</label
                    >
                    <select
                      class="form-control custom-select mr-sm-2"
                      id="number"
                      @change="changePaginationRows($event)"
                    >
                      <option>12</option>
                      <option>24</option>
                      <option>48</option>
                      <option>96</option> </select
                    ><small
                      class="form-text text-muted text-nowrap d-none d-sm-block"
                      >From {{ recordsLength }} Payments
                    </small>
                  </div>
                </div>
                <div class="form-inline d-md-flex flex-nowrap pb-3">
                  <input
                    type="search"
                    v-model="filterKey"
                    placeholder="Search for a payment"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="table-responsive table-responsive-sm">
                <table class="table table-sm mb-0 table-centered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date Created</th>
                      <th>Given Offer</th>
                      <th>Amount</th>
                      <th>Customer</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(payment, index) in filteredRecords"
                      :key="'payment' + index"
                    >
                      <td>{{ incrementIndex(index) }}</td>
                      <td>{{ dukaHotelDate(payment.offer_date) }}</td>
                      <td>
                        <span class="ellipsis-text">{{
                          payment.offer_title
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          payment.offer_currency +
                            " " +
                            addComma(payment.offer_amount)
                        }}</span>
                      </td>
                      <td>
                        {{
                          payment.customer_firstname +
                            " " +
                            payment.customer_lastname
                        }}
                      </td>
                      <td>
                        <span
                          class="badge badge-success"
                          v-if="Number(payment.offer_paid)"
                          >Paid</span
                        >
                        <span class="badge badge-danger" v-else>Pending</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr class="pb-4 mb-2" />
              <pagination
                :current_page="currentPage"
                :row_count_page="rowCountPage"
                @page-update="pageUpdate"
                :total_records="totalRows"
                :page_range="pageRange"
                prevIcon="ti-arrow-left"
                nextIcon="ti-arrow-right"
                dotsIcon="ti-more"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import MyPaginations from "@/components/Paginations.vue";
export default {
  name: "OffersPayments",
  components: {
    pagination: MyPaginations
  },
  data: () => ({}),
  created() {
    this.responseRef = "payments";
    this.paginationURL = this.$route.query["offer-id"]
      ? "duka-root/get-offer-payments/" + this.$route.query["offer-id"]
      : "duka-root/get-offer-payments";
    this.loadPaginationRecords();
  }
};
</script>
