var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pt-4"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4 mb-2",attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb breadcrumb-links breadcrumb-primary"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._l((_vm.breadcrumbItems),function(item){return _c('li',{key:'breadcrumbItems-' + item + _vm.uuidv4(),staticClass:"breadcrumb-item",class:{
                    active: _vm.isEqual(
                      _vm.breadcrumbItems.length,
                      _vm.breadcrumbItems.indexOf(item)
                    )
                  }},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v(_vm._s(item))]),(
                      _vm.breadcrumbItems.length > 1 &&
                        _vm.isEqual(
                          _vm.breadcrumbItems.length,
                          _vm.breadcrumbItems.indexOf(item)
                        )
                    )?_c('router-link',{staticClass:"btn btn-primary btn-sm ml-3",attrs:{"to":{
                      name: 'Productsvariations',
                      params: { slug: _vm.currentCategorySlug }
                    }}},[_vm._v("variations")]):_vm._e()],1)})],2)]),_c('div',{staticClass:"row"},_vm._l((_vm.levels),function(level){return _c('div',{key:'category' + level,staticClass:"col-sm-4 mt-2"},[_c('duka-categories',{key:'category' + level,attrs:{"parentId":level,"childLevel":_vm.levels.indexOf(level) + 1,"pathCode":_vm.currentPathCode},on:{"showparent":_vm.getNewParent}})],1)}),0)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header bg-transparent"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Create and manage product categories")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"ti-list"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("Products")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("Categories")])])}]

export { render, staticRenderFns }