<template>
  <div class="page-content-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="page-title-box">
            <div class="btn-group float-right">
              <ol class="breadcrumb hide-phone p-0 m-0">
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)">Boyoro admin</a>
                </li>
                <li class="breadcrumb-item active">Content Management</li>
              </ol>
            </div>
            <h4 class="page-title">Dashboard</h4>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="card" v-if="newSection.section_content">
            <div class="card-body">
              <h4>{{ boxTitle }}</h4>
              <summernote
                name="editor"
                ref="wolfEditor"
                :model="newSection.section_content"
                @change="
                  value => {
                    newSection.section_content = value;
                  }
                "
              ></summernote>
              <button
                class="btn btn-primary mt-4"
                type="button"
                @click="savSection"
              >
                <span>Update Content</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WolfEditor from "@/components/WolfEditor";
export default {
  name: "sections",
  components: {
    summernote: WolfEditor
  },
  data: () => ({
    newSection: {
      section_content: null
    },
    boxTitle: "Edit About information"
  }),
  created() {
    if (this.$route.query.slug && this.$route.query.slug === "footer-text") {
      this.boxTitle = "Edit Footer Information";
    }
    this.getContent();
  },
  methods: {
    savSection() {
      let url = "duka-root/set-section/" + this.$route.query.slug;
      var formData = this.formData(this.newSection);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            window.location.reload();
          }
        });
    },
    getContent() {
      this.$store
        .dispatch(
          "dukaGetRequest",
          "duka-root/get-section/" + this.$route.query.slug
        )
        .then(response => {
          this.newSection.section_content = response.data;
          //this.$refs.wolfEditor.onInit();
        });
    }
  }
};
</script>
