<template>
  <section class="page-content-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="page-title-box">
            <div class="btn-group float-right">
              <ol class="breadcrumb hide-phone p-0 m-0">
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)">Boyoro admin</a>
                </li>
                <li class="breadcrumb-item active">Home page Banners</li>
              </ol>
            </div>
            <h4 class="page-title">Dashboard</h4>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header bg-transparent">
              <h4>Create Landing page Banners</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="saveBanner">
                <div class="form-group row">
                  <label class="col-sm-3">Banner Category</label>
                  <div class="col-sm-9">
                    <select
                      @change="setBannerPx($event)"
                      v-model="newBanner.group_id"
                      class="form-control"
                    >
                      <option value="null" disabled hidden
                        >Choose Banner category</option
                      >
                      <option
                        :value="category.id"
                        v-for="(category, key) in categories"
                        v-bind:key="key"
                        >{{ category.group_name }}</option
                      >
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3">Banner Title</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      v-model="newBanner.banner_title"
                      class="form-control"
                      placeholder="Banner main title"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3">Banner sub title</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      v-model="newBanner.banner_subtitle"
                      class="form-control"
                      placeholder="Banner sub title"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3">URL String</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      v-model="newBanner.url_string"
                      class="form-control"
                      placeholder="http://www.example.com"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3">Button caption</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      v-model="newBanner.url_label"
                      class="form-control"
                      placeholder="Go to my link"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3">Start Date</label>
                  <div class="col-sm-9">
                    <datepicker
                      v-model="start_date"
                      calendar-button-icon="ti-calendar"
                      name="start_date"
                      inputClass="form-control"
                      :clear-button="true"
                      :bootstrap-styling="true"
                      format="dd-MMM-yyyy"
                      id="start-date"
                    >
                      <div slot="beforeCalendarHeader" class="calender-header">
                        Starting date
                      </div>
                    </datepicker>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3">Expired date</label>
                  <div class="col-sm-9">
                    <datepicker
                      v-model="expired_date"
                      calendar-button-icon="ti-calendar"
                      name="expired_date"
                      inputClass="form-control"
                      :clear-button="true"
                      :bootstrap-styling="true"
                      format="dd-MMM-yyyy"
                      id="expired-date"
                    >
                      <div slot="beforeCalendarHeader" class="calender-header">
                        Expired date
                      </div>
                    </datepicker>
                  </div>
                </div>
                <div class="form-group row" v-show="newBanner.group_id">
                  <label class="col-sm-3">Banner Image</label>
                  <div class="col-sm-9">
                    <button
                      type="button"
                      class="btn btn-primary btn-block"
                      @click="toggleSingleUpload"
                    >
                      <span class="btn-inner--icon" style="font-size:12px">
                        <i class="dripicons-upload"></i>
                      </span>
                      <span
                        class="btn-inner--text mt--1"
                        style="position:relative;"
                      >
                        Upload banner image (
                        <b>{{ banner_width }} x {{ banner_height }}</b
                        >)
                      </span>
                    </button>
                    <my-upload
                      field="duka_upload"
                      @crop-success="dukaCropSuccess"
                      @crop-upload-success="dukaUploadSuccess"
                      v-model="showSingleUpload"
                      :width="banner_width"
                      :height="banner_height"
                      :url="trashedImageUrl"
                      lang-type="en"
                      ref="singleUpload"
                      img-format="png"
                    />
                  </div>
                </div>
                <hr />
                <div class="form-group row">
                  <div class="col-sm-12 text-right">
                    <button
                      class="btn btn-primary"
                      type="button"
                      @click="saveBanner"
                      :disabled="!activeCroppedImage"
                    >
                      Save Banner
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import myUpload from "vue-image-crop-upload";
import Datepicker from "vuejs-datepicker";
export default {
  name: "CmsBanner",
  components: {
    "my-upload": myUpload,
    Datepicker
  },
  data() {
    return {
      categories: [],
      banners: [],
      show: false,
      banner_height: 200,
      banner_width: 250,
      bannerURL: null,
      newBanner: {
        group_id: null,
        current_banner: null,
        banner_title: null,
        banner_subtitle: null,
        description: null,
        url_string: null,
        url_label: null,
        expired_date: null,
        start_date: null,
        date_created: null
      },
      start_date: null,
      expired_date: null
    };
  },
  created() {
    this.loadCategories();
    this.trashedImageUrl = this.trashedImageUrl + "/home-slide";
  },
  methods: {
    loadCategories() {
      this.$store
        .dispatch("dukaGetRequest", "duka-root/load-banners-categories")
        .then(response => {
          this.categories = response.data.dukaResponse;
        });
    },
    setBannerPx(event) {
      for (let arrItem of this.categories) {
        if (arrItem.id === event.target.value) {
          this.banner_height = Number(arrItem.height_px);
          this.banner_width = Number(arrItem.width_px);
          break;
        }
      }
    },
    saveBanner() {
      this.newBanner.start_date = this.start_date.toLocaleDateString();
      this.newBanner.expired_date = this.expired_date.toLocaleDateString();
      this.newBanner.current_banner = this.activeCroppedImage;
      this.newBanner.date_created = new Date().getTime();
      let url = "duka-root/set-banner";
      var formData = this.formData(this.newBanner);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.$router.push({ name: "cmsbannerslist" });
          }
        });
    }
  }
};
</script>
