<template>
  <div class="page-content-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="page-title-box">
            <div class="btn-group float-right">
              <ol class="breadcrumb hide-phone p-0 m-0">
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)">Boyoro admin</a>
                </li>
                <li class="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
            <h4 class="page-title">Dashboard</h4>
          </div>
        </div>
      </div>
      <div class="row dash-row">
        <div class="col-lg-2" v-if="false">
          <div class="card">
            <div class="card-body py-0">
              <div class="icon-contain">
                <div class="row align-items-center">
                  <div class="col-4 align-self-center">
                    <i class="dripicons-to-do text-gradient-success"></i>
                  </div>
                  <div class="col-8 text-right">
                    <h5 class="mt-0 mb-1">{{ companies }}</h5>
                  </div>
                  <p class="col-12 font-12 text-muted">Active companies</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2" v-if="false">
          <div class="card">
            <div class="card-body py-0">
              <div class="icon-contain">
                <div class="row align-items-center">
                  <div class="col-4 align-self-center">
                    <i class="dripicons-wallet text-gradient-danger"></i>
                  </div>
                  <div class="col-8 text-right">
                    <h5 class="mt-0 mb-1">{{ transfersNumber }}</h5>
                  </div>
                  <p class="col-12 font-12 text-muted">Money Transfers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="card">
            <div class="card-body py-0">
              <div class="icon-contain">
                <div class="row align-items-center">
                  <div class="col-4 align-self-center">
                    <i class="dripicons-user-group text-gradient-warning"></i>
                  </div>
                  <div class="col-8 text-right">
                    <h5 class="mt-0 mb-1">{{ users }}</h5>
                  </div>
                  <p class="col-12 font-12 text-muted">Registered Users</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="card">
            <div class="card-body py-0">
              <div class="icon-contain">
                <div class="row align-items-center">
                  <div class="col-4">
                    <i class="dripicons-stack text-gradient-primary"></i>
                  </div>
                  <div class="col-8 text-right">
                    <h5 class="mt-0 mb-1">{{ orders }}</h5>
                  </div>
                  <p class="col-12 font-12 text-muted">Products orders</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2" v-if="false">
          <div class="card">
            <div class="card-body pb-0">
              <div class="icon-contain">
                <div class="row">
                  <div class="col-4 align-self-center">
                    <i class="ti-gift text-gradient-primary"></i>
                  </div>
                  <div class="col-8 text-right">
                    <h5 class="mt-0 mb-1">{{ talar_offers }}</h5>
                  </div>
                  <p class="col-12 font-12 text-muted">Boyoro Offers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2" v-if="false">
          <div class="card">
            <div class="card-body pb-0">
              <div class="icon-contain">
                <div class="row">
                  <div class="col-4 align-self-center">
                    <i class="ti-gift text-gradient-primary"></i>
                  </div>
                  <div class="col-8 text-right">
                    <h5 class="mt-0 mb-1">{{ companies_offers }}</h5>
                  </div>
                  <p class="col-12 font-12 text-muted">Companies Offers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="card">
            <div class="card-body pb-0">
              <div class="icon-contain">
                <div class="row align-items-center">
                  <div class="col-12">
                    <h6 class="mt-0 mb-1">RWF {{ addComma(b_orders) || 0 }}</h6>
                  </div>
                  <p class="col-12 font-12 text-muted text-right">
                    Total Orders
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row dash-row">
        <div class="col-lg-2" v-if="false">
          <div class="card">
            <div class="card-body pb-0">
              <div class="icon-contain">
                <div class="row align-items-center">
                  <div class="col-12">
                    <h6 class="mt-0 mb-1">
                      USD {{ addComma(offers_total) || 0 }}
                    </h6>
                  </div>
                  <p class="col-12 font-12 text-muted text-right">
                    Offer Total
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2" v-if="false">
          <div class="card">
            <div class="card-body pb-0">
              <div class="icon-contain">
                <div class="row align-items-center">
                  <div class="col-12">
                    <h6 class="mt-0 mb-1">
                      USD {{ addComma(tranfers_total) || 0 }}
                    </h6>
                  </div>
                  <p class="col-12 font-12 text-muted text-right">
                    Tranfers Total
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2" v-if="false">
          <div class="card">
            <div class="card-body pb-0">
              <div class="icon-contain">
                <div class="row align-items-center">
                  <div class="col-12">
                    <h6 class="mt-0 mb-1">
                      USD {{ addComma(c_offers_total) }}
                    </h6>
                  </div>
                  <p class="col-12 font-12 text-muted text-right">
                    Companies offers
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2" v-if="false">
          <div class="card">
            <div class="card-body pb-0">
              <div class="icon-contain">
                <div class="row">
                  <div class="col-12">
                    <h6 class="mt-0 mb-1">
                      USD {{ addComma(c_withdraw_total) }}
                    </h6>
                  </div>
                  <p class="col-12 font-12 text-muted text-right">
                    Companies With.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2" v-if="false">
          <div class="card">
            <div class="card-body pb-0">
              <div class="icon-contain">
                <div class="row">
                  <div class="col-12">
                    <h6 class="mt-0 mb-1">USD {{ addComma(c_balance) }}</h6>
                  </div>
                  <p class="col-12 font-12 text-muted text-right">
                    Companies Balance
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3">
          <div class="card">
            <div class="card-body py-2 border-bottom">
              <h6>Balance (RWF)</h6>
            </div>
            <div class="card-body">
              <h5 class="text-primary text-right">
                RWF {{ addComma(balance_rw) || 0 }}
              </h5>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card">
            <div class="card-body py-2 border-bottom">
              <h6>Balance (USD)</h6>
            </div>
            <div class="card-body">
              <h5 class="text-primary text-right">
                USD {{ addComma(balance_usd) || 0 }}
              </h5>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header bg-primary">
              <h6 class="mb-0 text-white">Withdraw from AfriPay</h6>
            </div>
            <div class="card-body">
              <div class="general-label">
                <form class="form-inline" @submit.prevent="setWithdraw">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="newWithdraw.amount"
                      placeholder="Amount"
                    />
                  </div>
                  <div class="form-group m-l-10">
                    <select
                      v-model="newWithdraw.currency"
                      class="form-control custom-select ml-2 pr-2"
                    >
                      <option value="null" disabled hidden
                        >Currency &nbsp;&nbsp;</option
                      >
                      <option value="USD">USD</option>
                      <option value="RWF">RWF</option>
                    </select>
                  </div>
                  <button type="submit" class="btn btn-primary ml-2">
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdminLandingPage",
  data: () => ({
    orders: 0,
    companies: 0,
    transfersNumber: 0,
    products: 0,
    users: 0,
    transfers: [],
    b_orders: 0,
    offers_total: 0,
    tranfers_total: 0,
    c_offers_total: 0,
    c_withdraw_total: 0,
    c_balance: 0,
    talar_offers: 0,
    companies_offers: 0,
    balance_rw: 0,
    balance_usd: 0,
    newWithdraw: {
      amount: null,
      currency: null
    }
  }),
  created() {
    this.getLandingInfo();
  },
  methods: {
    getLandingInfo() {
      this.$store
        .dispatch("dukaGetRequest", "duka-root/admin-landing-data")
        .then(response => {
          this.orders = response.data.orders;
          this.companies = response.data.companies;
          this.users = response.data.users;
          this.transfersNumber = response.data.transfers_number;
          this.b_orders = response.data.b_orders;
          this.offers_total = response.data.offers_total;
          this.tranfers_total = response.data.tranfers_total;
          this.c_offers_total = response.data.c_offers_total;
          this.c_withdraw_total = response.data.c_withdraw_total;
          this.c_balance = response.data.c_balance;
          this.balance_rw = response.data.balance_rw;
          this.balance_usd = response.data.balance_usd;
          this.talar_offers = response.data.talar_offers;
          this.companies_offers = response.data.companies_offers;
        });
    },
    setWithdraw() {
      let url = "duka-root/set-withdraw";
      var formData = this.formData(this.newWithdraw);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.clearObject(this.newWithdraw);
            this.getLandingInfo();
          }
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
<style lang="css">
.icon-contain .row .col-12,
.dash-row .col-lg-2:not(:first-child),
.dash-row .col-lg-2:not(:last-child) {
  padding-right: 5px;
  padding-left: 5px;
}
</style>
