<template>
  <div class="page-content-wrapper">
    <duka-modal v-if="modalOpen" @close="toggleModal" style="display:block">
      <span slot="head" style="padding-top:10px">
        Edit Order #{{ choosenRow.order_number }}
        <span class="text-white float-right">{{ choosenRow.status }}</span>
      </span>
      <div slot="body">
        <div class="form-group">
          <label>Current Status</label>
          <select
            v-model="choosenRow.transferred"
            class="form-control custom-select"
          >
            <option value="0">Pending</option>
            <option value="1">Paid</option>
          </select>
        </div>
      </div>
      <div slot="foot">
        <button
          type="button"
          class="btn btn-primary ml-auto"
          @click="editStatus"
        >
          Save changes
        </button>
      </div>
    </duka-modal>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="page-title-box">
            <div class="btn-group float-right">
              <ol class="breadcrumb hide-phone p-0 m-0">
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)">Boyoro admin</a>
                </li>
                <li class="breadcrumb-item active">Transfers</li>
              </ol>
            </div>
            <h4 class="page-title">Dashboard</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <h5 class="header-title pb-3 mt-0">
                List of all Transfers
                <span class="badge badge-pill badge-primary">{{
                  transfers.length
                }}</span>
              </h5>
              <div class="table-responsive table-responsive-sm">
                <table class="table table-sm table-hover mb-0">
                  <thead>
                    <tr class="align-self-center">
                      <th>Transfer code</th>
                      <th>Sender Name</th>
                      <th>Reciever Name</th>
                      <th>Reciever Email</th>
                      <th>Transfer Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(transfer, index) in transfers"
                      :key="'transfer' + index"
                    >
                      <td>T-{{ transfer.transfer_code }}</td>
                      <td>
                        <i class="dripicons-user"></i>
                        {{ transfer.firstname }} {{ transfer.lastname }}
                      </td>
                      <td>
                        <i class="dripicons-user"></i>
                        {{ transfer.receiver_firstname }}
                        {{ transfer.receiver_lastname }}
                      </td>
                      <td>{{ transfer.receiver_email }}</td>
                      <td>{{ dukaHotelDate(transfer.date_transferred) }}</td>
                      <td>${{ addComma(transfer.amount_transfered) }}</td>
                      <td>
                        <span
                          class="badge badge-boxed badge-soft-warning"
                          v-if="!Number(transfer.transferred)"
                          >pending</span
                        >
                        <span
                          class="badge badge-boxed badge-soft-success"
                          v-else
                          >Paid</span
                        >
                      </td>
                      <td class="text-right">
                        <div class="dropdown">
                          <a
                            class="btn btn-sm btn-icon-only text-light"
                            href="javascript:void(0)"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              class="dripicons-view-list font-20 text-muted"
                            ></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          >
                            <a
                              class="dropdown-item"
                              href="javascript:void(0)"
                              @click.prevent="
                                selectRecord(transfer);
                                toggleModal();
                              "
                            >
                              <i class="dripicons-pencil"></i>Edit status</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--end table-responsive-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdminTransfers",
  data: () => ({
    transfers: [],
    newEdit: {
      id: null,
      status: null
    }
  }),
  created() {
    this.getTransfers();
  },
  methods: {
    editStatus() {
      let url = "duka-root/edit-tranfer-status";
      this.newEdit.id = this.choosenRow.id;
      this.newEdit.status = this.choosenRow.transferred;
      var formData = this.formData(this.newEdit);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.toggleModal();
          }
        })
        .catch(err => console.log(err));
    },
    getTransfers() {
      this.$store
        .dispatch("dukaGetRequest", "duka-root/get-transfers")
        .then(response => {
          this.transfers = response.data.transfers;
        });
    }
  }
};
</script>
