<template>
  <section class="py-4">
    <duka-modal-form
      v-if="modalOpen"
      @close="toggleModal"
      style="display:block"
      :modalSize="'default'"
      :headerColor="'#041e42'"
    >
      <span slot="head" style="padding-top:10px">Create a new company</span>
      <div slot="body">
        <div class="row">
          <div class="col-xl-10 mx-auto">
            <h4 class="mb-4 text-center">New company identification details</h4>
            <div class="clearfix"></div>
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="newCompany.company_name"
                />
                <label for="gr_login_password">Company Legal Name</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="newCompany.company_tin"
                />
                <label for="gr_login_password">Company TIN number</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="newCompany.company_email"
                />
                <label for="gr_login_password">Company Email address</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="newCompany.company_phone"
                />
                <label for="gr_login_password">Company Phone number</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="newCompany.company_address"
                />
                <label for="gr_login_password">Company Address</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>
            <p class="my-2 text-muted text-center">
              The new company will get email containing instruction to finish it
              account creation process
            </p>

            <button
              type="button"
              class="btn btn-lg btn-primary btn-block"
              @click="setCompany"
            >
              <span v-if="newCompany.id">Edit campany</span>
              <span v-else>Confirm and notify</span>
            </button>
          </div>
        </div>
      </div>
    </duka-modal-form>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-11">
          <div class="page-title-box">
            <div class="btn-group float-right">
              <ol class="breadcrumb hide-phone p-0 m-0">
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)">Boyoro admin</a>
                </li>
                <li class="breadcrumb-item active">Companies</li>
              </ol>
            </div>
            <h4 class="page-title">Dashboard</h4>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-11">
          <div class="card">
            <div class="card-body border-botttom">
              <h4 class="header-title">
                List of registered companies
                <button
                  type="button"
                  class="btn btn-outline-primary waves-effect waves-light float-right"
                  @click="toggleModal"
                >
                  <i class="dripicons-plus mr-2"></i>Add new
                </button>
              </h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table mb-0 table-centered">
                  <thead>
                    <tr>
                      <th>Company</th>
                      <th>Phone number</th>
                      <th>Email address</th>
                      <th>Tin Number</th>
                      <th>Company Address</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(company, index) in companies"
                      :key="'company' + index"
                    >
                      <td>
                        <img
                          :src="dukaURL + 'assets/avatar.png'"
                          class="rounded-circle thumb-sm mr-1"
                        />
                        {{ company.company_name }}
                      </td>
                      <td>{{ company.company_phone }}</td>
                      <td>{{ company.company_email }}</td>
                      <td>{{ company.company_tin }}</td>
                      <td>{{ company.company_address }}</td>
                      <td>
                        <router-link
                          :to="{
                            name: 'adminofferstracking',
                            query: { 'company-id': company.user_id }
                          }"
                          class="btn btn-sm btn-outline-success ml-2"
                          title="View Transactions"
                        >
                          <i class="ti-eye"></i>
                        </router-link>
                        <button
                          type="button"
                          class="btn btn-sm btn-danger ml-2"
                          @click="deleteCompany(company)"
                        >
                          <i class="ti-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Companies",
  data: () => ({
    newCompany: {
      company_name: null,
      company_phone: null,
      company_tin: null,
      company_email: null,
      company_address: null
    },
    companies: []
  }),
  created() {
    this.loadCompanies();
  },
  methods: {
    loadCompanies() {
      this.$store
        .dispatch("dukaGetRequest", "duka-root/get-companies")
        .then(response => {
          this.companies = response.data.dukaResponse;
        });
    },
    setCompany() {
      let url = "duka-root/create-company";
      var formData = this.formData(this.newCompany);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.clearObject(this.newCompany);
            this.loadCompanies();
            this.toggleModal();
          }
        })
        .catch(err => console.log(err));
    },
    deleteCompany(company) {
      if (confirm("Are you sure you want to delete this company?")) {
        this.$store
          .dispatch("dukaGetRequest", "duka-root/delete-company/" + company.id)
          .then(() => {
            this.loadCompanies();
          });
      }
    }
  }
};
</script>
